import styled from 'styled-components';
import { Button } from '@ojolabs/button';
import { defaultTheme } from '../../theme';

export const ButtonPrimaryThemed = styled(Button)`
  border-width: 0;
  border-style: none;
  background-color: ${defaultTheme.colors.emerald50};
  font-size: ${defaultTheme.fontSizes.md};
  font-weight: ${defaultTheme.fontWeights.regular};

  &:disabled {
    background-color: ${defaultTheme.colors.gray10};
    color: ${defaultTheme.colors.gray50};
    opacity: unset;
  }

  &:not([disabled]) {
    color: ${defaultTheme.colors.white};
    &:hover {
      background-color: ${defaultTheme.colors.emerald60};
    }
    &:active {
      background-color: ${defaultTheme.colors.emerald70};
    }
  }
`;
