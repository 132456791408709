import {
  expectedQueryParams,
  ExpectedQueryParams,
  QueryParamDataFields,
  Country,
} from './types';
import { convertStringListIntoArray } from '../utils';
import queryString from 'query-string';

const SESSION_ID = 'sessionId';

export const convertQueryParamsToRequestFieldsCanada = (
  queryParams: ExpectedQueryParams
): QueryParamDataFields => {
  return {
    id: 'NOT_INTEGRATED',
    phone: '',
    email: '',
    highOpportunityMarkets: 0,
    cities: [],
    zipCodes: [],
    firstName: '',
    lastName: '',
    state: '',
    country: queryParams.country ? (queryParams.country as Country) : 'CANADA',
    utmSource: queryParams.utm_source,
    utmCampaign: queryParams.utm_campaign,
    utmTerm: queryParams.utm_id,
    utmMedium: queryParams.utm_medium,
  };
};

export const convertQueryParamsToRequestFields = (
  queryParams: ExpectedQueryParams
): QueryParamDataFields => {
  const {
    cellPhone,
    emailAddress,
    zipcodesHighOpp,
    firstName,
    lastName,
    state,
    country,
    primaryMarket,
    selectedZipCodes,
    utm_source,
    utm_campaign,
    utm_id,
    utm_medium,
  } = queryParams;
  return {
    id: 'NOT_INTEGRATED',
    phone: cellPhone,
    email: emailAddress,
    highOpportunityMarkets: parseInt(zipcodesHighOpp, 10),
    cities: convertStringListIntoArray(primaryMarket),
    zipCodes: convertStringListIntoArray(selectedZipCodes),
    firstName,
    lastName,
    state,
    country: country as Country,
    utmSource: utm_source,
    utmCampaign: utm_campaign,
    utmTerm: utm_id,
    utmMedium: utm_medium,
  };
};
export const storeSessionId = (sessionId: string) =>
  localStorage.setItem(SESSION_ID, sessionId);

export const getSessionId = () => localStorage.getItem(SESSION_ID);

export const removeSessionId = () => localStorage.removeItem(SESSION_ID);

const verifyParamsPresent = (queryParams: queryString.ParsedQuery<string>) => {
  let allKeysPresent = true;
  const optional_keys = ['utm_campaign', 'utm_id', 'utm_medium', 'utm_source'];
  for (const key in expectedQueryParams) {
    if (!(key in queryParams || optional_keys.includes(key))) {
      allKeysPresent = false;
      //TODO: change this console log for with other methods
      //console.log(`missing ${key} from query params`);
    }
  }
  return allKeysPresent;
};

export const clearQueryParams = () => {
  const persistRedirectFlag = !redirectOnReloadIsEnabled();
  if (persistRedirectFlag) {
    window.history.replaceState(null, '', '?redirectOnReload=false');
  } else {
    window.history.replaceState(null, '', '');
  }
};

export const getQueryParams = (): ExpectedQueryParams | null => {
  const search = window.location.search;
  if (search.length > 0) {
    const allParams = queryString.parse(search);
    if (verifyParamsPresent(allParams)) {
      return allParams as ExpectedQueryParams;
    }
  }
  return null;
};

export const getQueryParamsCanada = (): ExpectedQueryParams | null => {
  const search = window.location.search;
  if (search.length > 0) {
    const allParams = queryString.parse(search);
    return allParams as ExpectedQueryParams;
  }
  return null;
};

/**
 * @returns True when query param is provided and it is not equal to 'false'. False otherwise.
 */
export const redirectOnReloadIsEnabled = () => {
  const search = window.location.search;
  if (search.length > 0) {
    const allParams = queryString.parse(search);
    if (allParams.redirectOnReload === 'false') {
      return false;
    }
  }
  return true;
};

export const redirectToOjoAgentApplicationPage = () => {
  //would be nice to throw a log here so we know how many people are coming in without params
  window.location.replace('https://ojo.com/agent-application');
};

export const redirectToOjoTeamPage = () => {
  //would be nice to throw a log here so we know how many people are coming in without params
  window.location.replace('https://ojo.com/agent-teams');
};
