//this is adapted from agent-tools..
//However we should add the agent-tools one into foundation so we can reuse it

import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Box } from '@ojolabs/layout';
import { defaultTheme } from '../../theme';

const animatedGradient = keyframes`
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`;

const BehindLogoGradient = styled(Box)`
  height: 68px;
  width: 68px;
  position: absolute;
  border-radius: 100%;
  border: 4px solid ${defaultTheme.colors.emerald50};
  border-left-color: ${defaultTheme.colors.emerald20};
  border-top-color: ${defaultTheme.colors.emerald20};
  z-index: -1;
  animation: ${animatedGradient} 1s infinite linear;
`;

const LoadingScreenStyled = styled(Box)`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default class Loading extends React.Component {
  static displayName = 'Loading';

  render() {
    return (
      <LoadingScreenStyled>
        <BehindLogoGradient />
      </LoadingScreenStyled>
    );
  }
}
