import * as React from 'react';
import { Box } from '@ojolabs/layout';
import styled from 'styled-components';

export interface ImageScreenProps {
  image: string;
  alt: string;
}

const DivStyled = styled(Box)`
  height: 446px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageSrcStyled = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: left;
  border-radius: 12px;
`;

export const ImageScreen: React.FC<ImageScreenProps> = ({ image, alt }) => {
  return (
    <DivStyled mt={'std24'}>
      <ImageSrcStyled src={image} alt={alt} title={alt} />
    </DivStyled>
  );
};
