import { configureStore } from '@reduxjs/toolkit';
import agentApplicationReducer from './slices/agentApplicationSlice';
import { useDispatch } from 'react-redux';
import { splitReducer } from '@splitsoftware/splitio-redux';

export const store = configureStore({
  reducer: {
    agentApplication: agentApplicationReducer,
    splitio: splitReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); //
