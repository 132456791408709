import * as React from 'react';
import Loading from '@ojolabs/react-icons/loading';
import { SmallProgressBar } from '../GenericComponents/SmallProgressBar';
import { Heading2, Typography } from '@ojolabs/typography';
import { Box, Flexbox, Center } from '@ojolabs/layout';
import styled, { keyframes } from 'styled-components';
import { useWindowDimensions } from '../customHooks';
import { defaultTheme } from '../../theme';
import { noop } from '../utils';
import { ButtonPrimaryThemed } from './ButtonsThemed';
import { FontIcon } from './FontIcon';

export interface CustomHeaderProps {
  title: string;
  subtitle?: string | null;
}
export interface FlowContainerProps {
  onBack?: () => void;
  flowLength?: number;
  currentIndex?: number;
  title: string;
  subtitle?: string | null;
  SubtitleComponent?: React.FC;
  onClickNext: () => void;
  isDisabled: boolean;
  isLoading?: boolean;
  testId: string;
  continueButtonTextOverride?: string;
  CustomHeader?: React.FC<CustomHeaderProps>;
  CustomSubHeader?: React.FC;
  CustomFooter?: React.FC;
}

const FlowContainerStyled = styled(Box)`
  padding: ${props => props.theme.space[5]};
  max-width: ${props => props.theme.breakpoints[0]}px;
  width: 100%;
  position: fixed;
  padding-bottom: 8rem;
  margin-bottom: 2.875rem;
  top: 0;
  bottom: calc(1rem + env(safe-area-inset-bottom));
  height: 100%;
  overflow: scroll;

  /* hide the scrollbar but still have it usable */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TitleContainer = styled(Box)`
  margin-top: ${props => props.theme.space[8]};
  margin-bottom: ${props => props.theme.space[5]};
`;

const OnNextButtonContainerMobile = styled(Box)`
  padding: ${props => props.theme.space[5]};
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0 3px 14px rgba(2, 27, 66, 0.12);
`;

const OnNextButtonContainerDesktop = styled(Box)`
  padding: ${props => props.theme.space[5]} 0px;
  width: 100%;
`;

const rotationAnimation = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const LoadingIcon = styled(Loading)`
  color: black;
  height: 1em;
  margin-right: 0.5em;
  animation: ${rotationAnimation} 2s linear infinite;
`;

const ContinueButton: React.FC<{
  isDisabled: boolean;
  isLoading: boolean;
  onClick: () => void;
  continueButtonTextOverride?: string;
}> = ({ isDisabled, isLoading, onClick, continueButtonTextOverride }) => (
  <ButtonPrimaryThemed
    fluid
    disabled={isDisabled || isLoading}
    onClick={onClick}
  >
    {isLoading ? (
      <>
        <LoadingIcon />
        Processing...
      </>
    ) : continueButtonTextOverride ? (
      continueButtonTextOverride
    ) : (
      'Continue'
    )}
  </ButtonPrimaryThemed>
);

export const FlowContainer: React.FC<FlowContainerProps> = ({
  children,
  onBack = noop,
  flowLength = 0,
  currentIndex = 0,
  title,
  subtitle,
  SubtitleComponent,
  onClickNext,
  isDisabled = false,
  isLoading = false,
  testId,
  continueButtonTextOverride,
  CustomHeader,
  CustomSubHeader,
  CustomFooter,
}) => {
  const { width } = useWindowDimensions();

  const useDesktopView = width > defaultTheme.breakpoints[0];

  return (
    <Center centerChildren={true} overflowY={'scroll'} data-testid={testId}>
      <FlowContainerStyled>
        {CustomHeader ? (
          <CustomHeader title={title} subtitle={subtitle} />
        ) : (
          <>
            <Flexbox
              flexDirection={'row'}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                style={{
                  position: 'absolute',
                  left: '1rem',
                  height: '2.5rem',
                  cursor: 'pointer',
                }}
                onClick={onBack}
              >
                <FontIcon
                  style={{
                    fontSize: '2rem',
                  }}
                  data-testid="back-arrow"
                  iconName={'angle-left'}
                />
              </Box>
              <SmallProgressBar
                max={flowLength}
                value={currentIndex}
                label="Progress Bar"
                id=""
              />
            </Flexbox>
            <TitleContainer>
              {CustomSubHeader && <CustomSubHeader />}
              <Heading2 mb={'std24'}>{title}</Heading2>
            </TitleContainer>
            {SubtitleComponent && (
              <Typography>
                <SubtitleComponent />
              </Typography>
            )}
            {subtitle && (
              <Typography textStyleVariant={'regular'} mb={'std24'}>
                {subtitle}
              </Typography>
            )}
          </>
        )}
        {children}
        {CustomFooter && <CustomFooter />}
        {useDesktopView && (
          <OnNextButtonContainerDesktop>
            <ContinueButton
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={onClickNext}
              continueButtonTextOverride={continueButtonTextOverride}
            />
          </OnNextButtonContainerDesktop>
        )}
      </FlowContainerStyled>

      {!useDesktopView && (
        <OnNextButtonContainerMobile>
          <ContinueButton
            isDisabled={isDisabled}
            isLoading={isLoading}
            onClick={onClickNext}
            continueButtonTextOverride={continueButtonTextOverride}
          />
        </OnNextButtonContainerMobile>
      )}
    </Center>
  );
};

FlowContainer.displayName = 'FlowContainer';
