import { store } from './AgentRecruiting/reduxStore';
import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AgentApplication from './AgentRecruiting/Components/AgentApplication';
import { NavigateWithQueryParams } from './AgentRecruiting/Components/NavigateWithQueryParams';

export const ROUTE_NOT_FOUND = '/404';

export interface Props {}

export class App extends React.Component<Props> {
  static displayName = 'App';

  render() {
    return (
      <Provider store={store}>
        <InternalApp />
      </Provider>
    );
  }
}
interface InternalAppProps {}

export const InternalApp: React.FC<InternalAppProps> = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/r/BnvGiiBQ"
          element={<NavigateWithQueryParams to="/ca" />}
        />
        <Route path="/:country/:index" element={<AgentApplication />} />
        <Route path="/:country" element={<AgentApplication />} />
        <Route index element={<AgentApplication />} />
        <Route path="*" element={<AgentApplication />} />
      </Routes>
    </BrowserRouter>
  );
};

InternalApp.displayName = 'InternalApp';
