import React from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';

export interface NavigateWithQueryParamsProps {
  to: string;
}

export const NavigateWithQueryParams: React.FC<
  NavigateWithQueryParamsProps
> = props => {
  const [params] = useSearchParams();

  return <Navigate replace to={props.to + '?' + params.toString()} />;
};
