import { ConfigTypes, ScreenOrder } from './Config/flows';
import { Dictionary, omitBy, isNaN } from 'lodash';

import {
  MULTIPLE_CHOICE,
  TEXT_INPUT,
  IMAGE,
  defaultMultipleChoiceScreenDisabled,
  isMissingRequiredTextFields,
  defaultOnNext,
  textFieldsAreValid,
} from './Config/flowTypes';

import {
  InitialStateType,
  MultipleChoiceState,
  TextInputState,
} from './initialState';

export const PRODUCTION = 'production';
export const STAGING = 'staging';
export const BETA = 'beta';
export const LOCAL = 'local';

export type Environment =
  | typeof PRODUCTION
  | typeof STAGING
  | typeof BETA
  | typeof LOCAL;

interface FrontEndUrlsToApiUrlsAndEnvs {
  [key: string]: {
    env: Environment;
    apiUrl: string;
  };
}

export const frontEndUrlsToApiUrlsAndEnvs: FrontEndUrlsToApiUrlsAndEnvs = {
  'agent-recruiting-frontend.dev.aws.us-east-1.ojocore.com': {
    env: BETA,
    apiUrl: 'https://agent-recruiting-backend.dev.aws.us-east-1.ojocore.com',
  },
  'dev-fe.agentrecruiting.ojo.com': {
    env: BETA,
    apiUrl: 'https://dev-be.agentrecruiting.ojoapi.com',
  },
  'agent-recruiting-frontend.stage.aws.us-east-1.ojocore.com': {
    env: STAGING,
    apiUrl: 'https://agent-recruiting-backend.stage.aws.us-east-1.ojocore.com',
  },
  'stage-fe.agentrecruiting.ojo.com': {
    env: STAGING,
    apiUrl: 'https://stage-be.agentrecruiting.ojoapi.com',
  },
  'agent-recruiting-frontend.prod.aws.us-east-1.ojocore.com': {
    env: PRODUCTION,
    apiUrl: 'https://agent-recruiting-backend.prod.aws.us-east-1.ojocore.com',
  },
  'prod-fe.agentrecruiting.ojo.com': {
    apiUrl: 'https://prod-be.agentrecruiting.ojoapi.com',
    env: PRODUCTION,
  },
  'application.ojoagent.com': {
    apiUrl: 'https://prod-be.agentrecruiting.ojoapi.com',
    env: PRODUCTION,
  },
  'agentapplication.ojo.com': {
    apiUrl: 'https://prod-be.agentrecruiting.ojoapi.com',
    env: PRODUCTION,
  },
};

export const getEnv = (): Environment | undefined => {
  return frontEndUrlsToApiUrlsAndEnvs[
    window.location.host as keyof FrontEndUrlsToApiUrlsAndEnvs
  ]?.env;
};

export const getApiBaseURL = () => {
  return (
    frontEndUrlsToApiUrlsAndEnvs[
      window.location.host as keyof FrontEndUrlsToApiUrlsAndEnvs
    ]?.apiUrl || ''
  );
};

export function splitOnIndex<T>(index: number, array: Readonly<Array<T>>) {
  return index > 0 && index < array.length
    ? [array.slice(0, index), array.slice(index)]
    : [array, []];
}

export const checkIfRequiredFieldsMissing = (
  config: ConfigTypes,
  matchingState?: MultipleChoiceState | TextInputState
) => {
  if (
    config.type === MULTIPLE_CHOICE &&
    matchingState &&
    'selected' in matchingState
  ) {
    const checkDisabled = config.disabled
      ? config.disabled
      : defaultMultipleChoiceScreenDisabled;
    return checkDisabled(matchingState.selected);
  } else if (
    config.type === TEXT_INPUT &&
    matchingState &&
    'fields' in matchingState
  ) {
    return isMissingRequiredTextFields(matchingState.fields, config.fields);
  }
  return false;
};

export const inputIsInvalid = (
  config: ConfigTypes,
  matchingState?: MultipleChoiceState | TextInputState
) => {
  if (
    config.type === MULTIPLE_CHOICE &&
    matchingState &&
    'selected' in matchingState
  ) {
    const checkDisabled = config.disabled
      ? config.disabled
      : defaultMultipleChoiceScreenDisabled;
    return checkDisabled(matchingState.selected);
  } else if (
    config.type === TEXT_INPUT &&
    matchingState &&
    'fields' in matchingState
  ) {
    const checkDisabled = config.disabled
      ? config.disabled
      : textFieldsAreValid;
    return checkDisabled(matchingState.fields, config.fields);
  }
  return false;
};

export const getNextIndex = (
  currentIndex: number,
  screenOrder: ScreenOrder,
  config: ConfigTypes,
  entireState: InitialStateType,
  matchingState?: MultipleChoiceState | TextInputState
): number => {
  let nextIndex = defaultOnNext(currentIndex);

  if (
    matchingState &&
    'selected' in matchingState &&
    config.type === MULTIPLE_CHOICE
  ) {
    const optionsSelected = matchingState.selected;
    const getOnNext = config.onNext ? config.onNext : defaultOnNext;
    nextIndex = getOnNext(currentIndex, screenOrder, optionsSelected);
  } else if (
    matchingState &&
    'fields' in matchingState &&
    config.type === TEXT_INPUT
  ) {
    const fields = matchingState.fields;
    const getOnNext = config.onNext ? config.onNext : defaultOnNext;
    nextIndex = getOnNext(currentIndex, screenOrder, fields);
  } else if (config.type === IMAGE) {
    const getOnNext = config.onNext ? config.onNext : defaultOnNext;
    nextIndex = getOnNext(currentIndex);
  }

  const keys = Object.keys(screenOrder) as (keyof ScreenOrder)[];
  const nextStepName = keys[nextIndex];
  const nextStepConfig = screenOrder[nextStepName];

  if (
    nextStepConfig.shouldBeSkipped &&
    nextStepConfig.shouldBeSkipped(entireState)
  ) {
    return getNextIndex(nextIndex, screenOrder, nextStepConfig, entireState);
  } else {
    return nextIndex;
  }
};

export const getNextIndexHelper = (
  idx: number,
  state: InitialStateType,
  screenOrder: ScreenOrder,
  keys: (keyof typeof screenOrder)[]
): number => {
  const stepName = keys[idx];
  const configLocale = screenOrder[stepName];
  const stepState: MultipleChoiceState | TextInputState =
    state[configLocale.id as keyof InitialStateType];

  return getNextIndex(idx, screenOrder, configLocale, state, stepState);
};

const allStatesString =
  'Alabama-Alaska-Arizona-Arkansas-California-Colorado-Connecticut-Delaware-Florida-Georgia-Hawaii-Idaho-Illinois-Indiana-Iowa-Kansas-Kentucky-Louisiana-Maine-Maryland-Massachusetts-Michigan-Minnesota-Mississippi-Missouri-Montana-Nebraska-Nevada-New Hampshire-New Jersey-New Mexico-New York-North Carolina-North Dakota-Ohio-Oklahoma-Oregon-Pennsylvania-Rhode Island-South Carolina-South Dakota-Tennessee-Texas-Utah-Vermont-Virginia-Washington-West Virginia-Wisconsin-Wyoming';

const allProvincesString =
  'Alberta-British Columbia-Manitoba-New Brunswick-Newfoundland-Nova Scotia-Ontario-Prince Edward Island-Saskatchewan';

export const stateDropdownOptions = allStatesString.split('-').map(state => ({
  id: state,
  name: state,
}));

export const provinceDropdownOptions = allProvincesString
  .split('-')
  .map(province => ({
    id: province,
    name: province,
  }));

export const filterStringToOnlyNumbers = (inputString: string): string =>
  inputString.split(/ /)[0].replace(/[^\d]/g, '');

//https://developer.salesforce.com/docs/marketing/marketing-cloud/guide/using_regular_expressions_to_validate_email_addresses.html
export const isValidSalesforceEmail = (email: string) =>
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
    email
  );

export const isEmail = (email: string) => /^.+@.+$/.test(email);

//this assumes that the number is a masked input E164 number, so 12 is the correct amount
export const isPhoneNumber = (phone: string) => {
  return phone.length === 12;
};

export function turnEmptyOrUndefinedStringValuesToNull<T>(
  objectToConvert: object
): T {
  const newObject = {};
  for (const key in objectToConvert) {
    // ts doesn't know that key is a key for objectToConvert...
    // @ts-ignore
    const value = objectToConvert[key];
    if (
      value !== null &&
      (value === '' || value === undefined || value.length === 0)
    ) {
      // @ts-ignore
      newObject[key] = null;
    } else {
      // @ts-ignore
      newObject[key] = value;
    }
  }
  // @ts-ignore
  return newObject;
}

// tslint:disable-next-line:no-empty
export const noop = () => {};

//E.G. turn "bob, dora, kimberly" into ["bob", "dora", "kimberly"]
export const convertStringListIntoArray = (s: string) =>
  s.split(',').map((zip: string) => zip.trim());

/**
 * Given a TextInputState, returns a boolean if for at least one field, isInvalidInput === true
 * @return {boolean} at least one field in matchingState has isInvalidInput === true
 */
export const matchingStateHasAtLeastOneInvalidValue = (
  matchingState?: MultipleChoiceState | TextInputState
) => {
  if (!matchingState) {
    return false;
  }
  if ('fields' in matchingState) {
    for (const key in matchingState.fields) {
      if (matchingState.fields[key].isInvalidInput) {
        return true;
      }
    }
  }
  return false;
};

export const getCountryCode = (_phone: string) => '+1'; // For now we'll assume all numbers use +1 as country code

export const convertToE164 = (countryCode: string, phone: string) => {
  const formattedCode = countryCode.trim();
  const formattedPhone = phone.replace(/\D/g, '');

  return `${formattedCode}${formattedPhone}`;
};
export const omitNullAndUndefined = <T extends object>(o: Dictionary<T>) =>
  omitBy(o, isNaN);
