import React from 'react';
import {
  MULTIPLE_CHOICE_CHECK,
  MULTIPLE_CHOICE_OTHER_ID,
  THANK_YOU_PAGE,
  MULTIPLE_CHOICE,
  MultipleChoiceScreenConfig,
  TEXT_INPUT,
  TextInputScreenConfig,
  NUMBER,
  STATE_DROPDOWN,
  OptionsSelected,
  IMAGE,
  ImageScreenConfig,
  MULTI_LINE,
  EMAIL,
  PHONE,
  HORIZONTAL,
  ThankYouScreenConfig,
  ReadOnlyScreenConfig,
  READ_ONLY,
  PROVINCE_DROPDOWN,
} from './flowTypes';
import { PillSelectOption } from '../Components/PillSelect';
import { redirectToOjoTeamPage } from '../API/queryParamsToRequest';
import teamImage from '../../../assets/images/teams-image.png';
import { RBCScreenSubHeader } from '../Components/RBCScreenSubHeader';
import { RBCDisclaimerSubtitle } from '../Components/RBCDisclaimerSubtitle';
import { RBCInfoScreenSubtitle } from '../Components/RBCInfoScreenSubtitle';
import { FieldsInState, InitialStateType } from '../initialState';
import { OjoLogoHeader } from '../Components/OjoLogoHeader';
import { TermsAndConditionsFooter } from '../Components/TermsAndConditionsFooter';

export const consentPage: MultipleChoiceScreenConfig = {
  id: 'consentPage',
  title: 'Consent to contact',
  subtitle:
    'Do you consent to receiving marketing and promotional messages by telephone, text message, and email from Movoto Home, including information and updates about properties of interest and services and features of Movoto Home and our selected partners? You may withdraw consent at any time. Message and date rates may apply. Consent is not required to receive Real Estate services.',
  type: MULTIPLE_CHOICE_CHECK,
  options: [
    { label: 'I consent', id: 'iConsent' },
    { label: 'I do not consent', id: 'iDoNotConsent' },
  ],
  disabled: () => false,
  otherFreeformCustomId: undefined,
};

export const howDidYouHearAboutOjo: MultipleChoiceScreenConfig = {
  id: 'howDidYouHearAboutOjo',
  title: 'How did you hear about Movoto Select Network?',
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Contacted by Movoto', id: 'Contacted by OJO' },
    {
      label: 'Referred by Another Agent',
      id: 'Referred by another agent',
    },
    {
      label: 'My Broker',
      id: 'My broker',
    },
    {
      label: 'Facebook',
      id: 'Facebook',
    },
    {
      label: 'Linkedin',
      id: 'Linkedin',
    },
    {
      label: 'Email Marketing',
      id: 'Email Marketing',
    },
    {
      label: 'Relocation Director',
      id: 'Relocation Director',
    },
    {
      label: 'Other',
      id: MULTIPLE_CHOICE_OTHER_ID,
    },
  ],
  onNext: (
    currentIndex: number,
    screenOrder: ScreenOrder,
    selected: string[]
  ) => {
    if (
      selected.includes('Referred by another agent') ||
      selected.includes('My broker')
    ) {
      return getIndexOfConfig('whoReferredYou', screenOrder);
    } else if (selected.length > 0) {
      return getIndexOfConfig('whichOfTheFollowingDescribesYou', screenOrder);
    }
    return currentIndex;
  },
};

// Just using this to test that the app is differentiating between US and Canada - this will be removed once we can confirm.
const howDidYouHearAboutOjoCanada: MultipleChoiceScreenConfig = {
  id: 'howDidYouHearAboutOjo',
  title: 'How did you hear about Movoto Select Network?',
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Contacted by Movoto', id: 'Contacted by OJO' },
    {
      label: 'Invited by Royal Bank of Canada',
      id: 'Invited by Royal Bank of Canada',
    },
    {
      label: 'Referred by another agent',
      id: 'Referred by another agent',
    },
    {
      label: 'My broker',
      id: 'My broker',
    },
    {
      label: 'Facebook',
      id: 'Facebook',
    },
    {
      label: 'Linkedin',
      id: 'Linkedin',
    },
    {
      label: 'Email Marketing',
      id: 'Email Marketing',
    },
    {
      label: 'Relocation Director',
      id: 'Relocation Director',
    },
    {
      label: 'Other',
      id: MULTIPLE_CHOICE_OTHER_ID,
    },
  ],
  onNext: (
    currentIndex: number,
    screenOrder: ScreenOrder,
    selected: string[]
  ) => {
    if (
      selected.includes('Referred by another agent') ||
      selected.includes('My broker')
    ) {
      return getIndexOfConfig('whoReferredYou', screenOrder);
    } else if (selected.length > 0) {
      return getIndexOfConfig('whichOfTheFollowingDescribesYou', screenOrder);
    }
    return currentIndex;
  },
};

export const whoReferredYou: TextInputScreenConfig = {
  id: 'whoReferredYou',
  title: 'Who referred you?',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'referenceName',
      title: 'Referring Agent or Broker Name',
      maxLength: 255,
    },
  ],
};

// This is the list of options for 'whichOfTheFollowingDescribesYou'
const whichOfTheFollowingDescribesYouOptions: PillSelectOption[] = [
  { label: 'Individual Agent', id: 'Individual Agent' },
  {
    label: 'Team Lead and Rainmaker',
    id: 'Team Lead and Rainmaker',
  },
  {
    label: 'ISA on a team',
    id: 'ISA on a team',
  },
  {
    label: 'Agent on a team',
    id: 'Agent on a team',
  },
];

const agentTeamOptions = whichOfTheFollowingDescribesYouOptions.slice(2);

const whichOfTheFollowingDescribesYou: MultipleChoiceScreenConfig = {
  id: 'whichOfTheFollowingDescribesYou',
  title: 'Which of the following describes you?',
  type: MULTIPLE_CHOICE,
  options: whichOfTheFollowingDescribesYouOptions,
  onNext: (_currentIndex, screenOrder, optionsSelected) => {
    if (
      0 < optionsSelected.length &&
      agentTeamOptions.find(
        (option: { id: string }) => option.id === optionsSelected[0]
      )
    ) {
      // redirect to agent team page
      return getIndexOfConfig('teamDeadEndPage', screenOrder);
    } else {
      //else, continue flow
      return getIndexOfConfig('howManyYearsOfExperienceDoYouHave', screenOrder);
    }
  },
};

const teamDeadEndPage: ImageScreenConfig = {
  id: 'teamDeadEndPage',
  title: 'Team Sign-Up',
  subtitle:
    'Please have your team owner or lead sign-up the group for Movoto Select Network and make sure they include your contact information under “Team Members”.',
  type: IMAGE,
  image: teamImage,
  alt: 'Signing up as a Team',
  onNext: currentIndex => {
    redirectToOjoTeamPage();
    return currentIndex;
  },
  continueButtonTextOverride: 'Learn More',
};

const howManyYearsOfExperienceDoYouHave: MultipleChoiceScreenConfig = {
  id: 'howManyYearsOfExperienceDoYouHave',
  title: 'How many years of experience do you have as a full-time agent?',
  type: MULTIPLE_CHOICE,
  options: [
    { label: '0 - 1 years', id: '0 - 1 years' },
    {
      label: '2 - 3 years',
      id: '2 - 3 years',
    },
    {
      label: '4 - 5 years',
      id: '4 - 5 years',
    },
    {
      label: '6 - 10 years',
      id: '6 - 10 years',
    },
    {
      label: '11+ years',
      id: '11+',
    },
  ],
  multiSelect: false,
};

const howManyTransactionsClosed: TextInputScreenConfig = {
  id: 'howManyTransactionsClosed',
  title: 'How many transactions have you closed in the last 12 months?',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'qtyTransactions',
      title: '# of Transactions',
      placeholder: '000',
      type: NUMBER,
      maxLength: 6,
    },
  ],
};

const realEstateInformation: TextInputScreenConfig = {
  id: 'realEstateInformation',
  title: 'Real Estate Information',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'licenseId',
      title: 'License ID',
      required: true,
      placeholder: 'Enter real estate license #',
      maxLength: 40,
    },
    {
      id: 'licenseState',
      title: 'License State',
      type: STATE_DROPDOWN,
      required: true,
      placeholder: 'Choose State',
      maxLength: 50,
    },
  ],
};

const brokerageInformation: TextInputScreenConfig = {
  id: 'brokerageInformation',
  title: 'Brokerage Information',
  subtitle:
    'Please make sure the provided name and email for the managing broker/broker of record is authorized to sign-off on agent referral agreements.',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'brokerageName',
      title: 'Brokerage Name',
      placeholder: 'Name',
      maxLength: 75,
    },
    {
      id: 'brokerageAddress',
      title: 'Brokerage Address',
      placeholder: 'Street address',
      maxLength: 32768,
    },
    {
      id: 'brokerName',
      title: 'Managing Broker / Broker of Record Full Name',
      placeholder: 'First Name Last Name',
      maxLength: 100,
    },
    {
      id: 'brokerageEmail',
      title: 'Managing Broker / Broker of Record Email',
      placeholder: 'someone@email.com',
      type: EMAIL,
      maxLength: 75,
    },
    {
      id: 'brokeragePhone',
      title: 'Managing Broker / Broker of Record Phone',
      placeholder: '(000) 000 - 0000',
      type: PHONE,
      maxLength: 15,
    },
  ],
};

const rbcMember: MultipleChoiceScreenConfig = {
  id: 'rbcMember',
  orientation: HORIZONTAL,
  customSubHeader: RBCScreenSubHeader,
  title: 'Are you a member of the RBC Referral Awards program?',
  SubtitleComponent: () => (
    <div>
      To find your ID number or see more information, please visit{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://referralawards.rbc.com/"
      >
        RBC Referral Awards Program
      </a>
    </div>
  ),
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Yes', id: 'Yes' },
    {
      label: 'No',
      id: 'No',
    },
  ],
  onNext: (
    _currentIndex: number,
    screenOrder: ScreenOrder,
    optionsSelected: OptionsSelected
  ) => {
    if (optionsSelected.includes('Yes')) {
      return getIndexOfConfig('rbcNumber', screenOrder);
    } else {
      return getIndexOfConfig('rbcInfoScreen', screenOrder);
    }
  },
};

const rbcNumber: TextInputScreenConfig = {
  id: 'rbcNumber',
  customSubHeader: RBCScreenSubHeader,
  title: 'Please enter your RBC Referral Awards Participant ID',
  type: TEXT_INPUT,
  fields: [
    {
      id: 'rbcNumber',
      title: 'Referral Awards Participant ID',
      maxLength: 8,
    },
  ],
  onNext: (
    _currentIndex: number,
    screenOrder: ScreenOrder,
    _fields: FieldsInState
  ) => {
    return getIndexOfConfig('areYouSigningUpAsTeam', screenOrder);
  },
};

//todo - just make read only text screen
const rbcInfoScreen: ReadOnlyScreenConfig = {
  id: 'rbcInfoScreen',
  customSubHeader: RBCScreenSubHeader,
  title: 'RBC Referral Awards Program',
  SubtitleComponent: RBCInfoScreenSubtitle,

  type: READ_ONLY,
};

const rbcDisclaimer: ReadOnlyScreenConfig = {
  id: 'rbcDisclaimer',
  customSubHeader: RBCScreenSubHeader,
  title: 'RBC Disclaimer',
  SubtitleComponent: RBCDisclaimerSubtitle,
  type: READ_ONLY,
};

const areYouSigningUpAsTeam: MultipleChoiceScreenConfig = {
  id: 'areYouSigningUpAsTeam',
  title: 'Are you signing up as a team?',
  subtitle: `You can sign up as a team for the Movoto Select Network, with no restrictions on team size. We recommend selecting members carefully as the program is designed for top producers.`,
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Yes', id: 'Yes' },
    {
      label: 'No',
      id: 'No',
    },
  ],
  orientation: HORIZONTAL,
  onNext: (
    currentIndex: number,
    screenOrder: ScreenOrder,
    optionsSelected: OptionsSelected
  ) => {
    if (optionsSelected.includes('Yes')) {
      return currentIndex + 1;
    } else {
      return getIndexOfConfig('propertyTypes', screenOrder);
    }
  },
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const signingUpAsATeamSplashPage: ImageScreenConfig = {
  id: 'signingUpAsATeamSplashPage',
  title: 'Team Sign-Up',
  subtitle:
    'Be selective when choosing your Lead Receiver and participating agents. Your top-performing agents will fare best in this program.',
  type: IMAGE,
  image: teamImage,
  alt: 'Signing up as a Team',
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const teamInformation: TextInputScreenConfig = {
  id: 'teamInformation',
  type: TEXT_INPUT,
  title: 'Team Information',
  subtitle: `Please only count team members who will be receiving Movoto Select Network referrals (including yourself).`,
  fields: [
    {
      id: 'teamMembers',
      title: '# of team members participating',
      placeholder: '00',
      type: NUMBER,
      maxLength: 6,
    },
    {
      id: 'teamName',
      title: 'Team Name',
      placeholder: 'Name',
      maxLength: 100,
    },
  ],
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const leadReceiver: TextInputScreenConfig = {
  id: 'leadReceiver',
  type: TEXT_INPUT,
  title: 'Lead Receiver',
  subtitle:
    'Please list your Lead Receiver(s) below in the following format: First Name, Last Name, Preferred Name, Mobile Phone Number, Email',
  fields: [
    {
      //todo - make the id of these fields have to match to an id in the state
      id: 'leadReceiverData',
      title: 'Lead Receiver Details',
      placeholder:
        'First Name, Last Name, Preferred Name, Mobile Phone Number, Email',
      type: MULTI_LINE,
      maxLength: 32000,
    },
  ],
  subSectionTitle: 'What is a Lead Receiver to us?',
  subSectionBody: `The Lead Receiver is an agent, ISA, or group of agents on your team who you will appoint to receiver leads from Movoto and who will be initially introduced to the customer via a phone call.\n\nBe sure to choose individuals who are highly responsive and willing to pick up the phone quickly and often.`,
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const teamMembers: TextInputScreenConfig = {
  id: 'teamMembers',
  type: TEXT_INPUT,
  title: 'Team Members',
  subtitle:
    'Please list your Lead Receiver(s) below in the following format: First Name, Last Name, Preferred Name, Mobile Phone Number, Email',
  fields: [
    {
      id: 'teamMembers',
      title: 'Team Member Details',
      placeholder:
        'First Name, Last Name, Preferred Name, Mobile Phone Number, Email',
      type: MULTI_LINE,
      required: false,
      maxLength: 32000,
    },
  ],
  subSectionTitle: 'What is a Lead Receiver to us?',
  subSectionBody:
    'The Lead Receiver is an agent, ISA, or group of agents on your team who you will appoint to receiver leads from Movoto and who will be initially introduced to the customer via a phone call.\n' +
    '\n' +
    'Be sure to choose individuals who are highly responsive and willing to pick up the phone quickly and often.',
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const thankYouScreenCanada: ThankYouScreenConfig = {
  id: THANK_YOU_PAGE,
  type: THANK_YOU_PAGE,
  title: 'Your application is complete',
  learnMoreUrl: 'https://www.ojohome.ca/agent-teams/',
};

const thankYouScreenUS: ThankYouScreenConfig = {
  id: THANK_YOU_PAGE,
  type: THANK_YOU_PAGE,
  title: 'Your application is complete',
  learnMoreUrl: 'https://ojo.com/agent-teams/',
};

const doesYourTeamHaveISA: MultipleChoiceScreenConfig = {
  id: 'doesYourTeamHaveISA',
  title: 'Does your team have an ISA who receives leads and distributes them?',
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Yes', id: 'Yes' },
    {
      label: 'No',
      id: 'No',
    },
  ],
  onNext: (currentIndex, screenOrder, optionsSelected) => {
    if (optionsSelected.includes('Yes')) {
      return currentIndex + 1;
    } else {
      return getIndexOfConfig('propertyTypes', screenOrder);
    }
  },
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const howManyISAsWork: MultipleChoiceScreenConfig = {
  id: 'howManyISAsWork',
  title: 'How many ISAs work on your team?',
  type: MULTIPLE_CHOICE,
  options: [
    { label: '1', id: '1' },
    { label: '2-3', id: '2-3' },
    { label: '4-5', id: '4-5' },
    { label: '6-10', id: '6-10' },
    { label: '11+', id: '11+' },
  ],
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const howManyISAsReceiveLeads: MultipleChoiceScreenConfig = {
  id: 'howManyISAsReceiveLeads',
  title: 'How many ISAs will receive these Movoto leads on your team?',
  type: MULTIPLE_CHOICE,
  options: [
    { label: '1', id: '1' },
    { label: '2-3', id: '2-3' },
    { label: '4-5', id: '4-5' },
    { label: '6-10', id: '6-10' },
    { label: '11+', id: '11+' },
    { label: 'Unsure', id: "Don't Know" },
  ],
  shouldBeSkipped: (state: InitialStateType): boolean =>
    isIndividualAgent(state),
};

const propertyTypes: MultipleChoiceScreenConfig = {
  id: 'propertyTypes',
  title: 'What property types do you service?',
  subtitle: `Choose as many options as you'd like`,
  type: MULTIPLE_CHOICE,
  multiSelect: true,
  options: [
    { label: 'Single-family Homes', id: 'Single Family Homes' },
    { label: 'Condos / Townhomes', id: 'Condos / Townhomes' },
    { label: 'Mobile / Manufactured', id: 'Mobile / Manufactured' },
    { label: 'Land', id: 'Land' },
    { label: 'Commercial', id: 'Commercial' },
    { label: 'Other', id: MULTIPLE_CHOICE_OTHER_ID },
  ],
  otherPlaceholder: 'Enter property type',
};

const additionalLanguagePreferenceCanada: MultipleChoiceScreenConfig = {
  id: 'additionalLanguagePreference',
  isFinalStep: true,
  title: 'What other languages do you speak?',
  subtitle:
    'Choose the languages you are most comfortable speaking with clients.',
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Spanish', id: 'Spanish' },
    { label: 'Cantonese', id: 'Cantonese' },
    { label: 'Mandarin', id: 'Mandarin' },
    { label: 'French', id: 'French' },
  ],
  disabled: () => false,
};

const additionalLanguagePreference: MultipleChoiceScreenConfig = {
  id: 'additionalLanguagePreference',
  isFinalStep: true,
  title: 'What other languages do you speak?',
  subtitle:
    'Choose the languages you are most comfortable speaking with clients.',
  type: MULTIPLE_CHOICE,
  options: [
    { label: 'Spanish', id: 'Spanish' },
    { label: 'Cantonese', id: 'Cantonese' },
    { label: 'Mandarin', id: 'Mandarin' },
  ],
  disabled: () => false,
};

const contactInfo: TextInputScreenConfig = {
  id: 'contactInfo',
  title: 'Apply for the Movoto Select Network',
  subtitle: 'Ready to apply? Start the process below.',
  type: TEXT_INPUT,
  customHeader: OjoLogoHeader,
  customFooter: TermsAndConditionsFooter,
  continueButtonTextOverride: 'Start Application',
  queryParamsNotRequired: true,
  fields: [
    {
      id: 'firstName',
      title: 'First Name',
      placeholder: 'Jan',
      halfWidth: true,
      maxLength: 100,
    },
    {
      id: 'lastName',
      title: 'Last Name',
      placeholder: 'Appleseed',
      halfWidth: true,
      maxLength: 100,
    },
    {
      id: 'phone',
      title: 'Mobile phone',
      placeholder: '(555) 123-4567',
      type: PHONE,
      maxLength: 15,
      validatePhone: true,
    },
    {
      id: 'email',
      title: 'Email',
      placeholder: 'jan.appleseed@email.com',
      type: EMAIL,
      maxLength: 75,
    },
    {
      id: 'province',
      title: 'Province',
      type: PROVINCE_DROPDOWN,
      maxLength: 50,
    },
    {
      id: 'provincialLicenseId',
      title: 'Provincial License ID',
      required: false,
      maxLength: 40,
    },
  ],
};

const isIndividualAgent = (state: InitialStateType): boolean =>
  state.whichOfTheFollowingDescribesYou.selected[0] === 'Individual Agent';

export const getIndexOfConfig = (
  configId: Screens,
  screenOrder: ScreenOrder
): number => {
  return Object.keys(screenOrder).indexOf(configId);
};

export type ConfigTypes =
  | MultipleChoiceScreenConfig
  | TextInputScreenConfig
  | ImageScreenConfig
  | ThankYouScreenConfig;

//the order of this object determines order of flow
export const usScreenOrder: UsScreenOrder = {
  howDidYouHearAboutOjo,
  whoReferredYou,
  whichOfTheFollowingDescribesYou,
  teamDeadEndPage,
  howManyYearsOfExperienceDoYouHave,
  howManyTransactionsClosed,
  realEstateInformation,
  brokerageInformation,
  areYouSigningUpAsTeam,
  signingUpAsATeamSplashPage,
  teamInformation,
  leadReceiver,
  teamMembers,
  doesYourTeamHaveISA,
  howManyISAsWork,
  howManyISAsReceiveLeads,
  propertyTypes,
  additionalLanguagePreference,
  thankYouScreen: thankYouScreenUS,
};

export const canadaScreenOrder: CanadaScreenOrder = {
  contactInfo,
  consentPage,
  howDidYouHearAboutOjo: howDidYouHearAboutOjoCanada,
  whoReferredYou,
  whichOfTheFollowingDescribesYou,
  teamDeadEndPage,
  howManyYearsOfExperienceDoYouHave,
  howManyTransactionsClosed,
  brokerageInformation,
  rbcMember,
  rbcNumber,
  rbcInfoScreen,
  rbcDisclaimer,
  areYouSigningUpAsTeam,
  signingUpAsATeamSplashPage,
  teamInformation,
  leadReceiver,
  teamMembers,
  doesYourTeamHaveISA,
  howManyISAsWork,
  howManyISAsReceiveLeads,
  propertyTypes,
  additionalLanguagePreference: additionalLanguagePreferenceCanada,
  thankYouScreen: thankYouScreenCanada,
};

export interface UsScreenOrder {
  howDidYouHearAboutOjo: MultipleChoiceScreenConfig;
  whoReferredYou: TextInputScreenConfig;
  whichOfTheFollowingDescribesYou: MultipleChoiceScreenConfig;
  teamDeadEndPage: ImageScreenConfig;
  howManyYearsOfExperienceDoYouHave: MultipleChoiceScreenConfig;
  howManyTransactionsClosed: TextInputScreenConfig;
  realEstateInformation: TextInputScreenConfig;
  brokerageInformation: TextInputScreenConfig;
  areYouSigningUpAsTeam: MultipleChoiceScreenConfig;
  signingUpAsATeamSplashPage: ImageScreenConfig;
  teamInformation: TextInputScreenConfig;
  leadReceiver: TextInputScreenConfig;
  teamMembers: TextInputScreenConfig;
  doesYourTeamHaveISA: MultipleChoiceScreenConfig;
  howManyISAsWork: MultipleChoiceScreenConfig;
  howManyISAsReceiveLeads: MultipleChoiceScreenConfig;
  propertyTypes: MultipleChoiceScreenConfig;
  additionalLanguagePreference: MultipleChoiceScreenConfig;
  thankYouScreen: ThankYouScreenConfig;
}

export interface CanadaScreenOrder {
  contactInfo: TextInputScreenConfig;
  consentPage: MultipleChoiceScreenConfig;
  howDidYouHearAboutOjo: MultipleChoiceScreenConfig;
  whoReferredYou: TextInputScreenConfig;
  whichOfTheFollowingDescribesYou: MultipleChoiceScreenConfig;
  teamDeadEndPage: ImageScreenConfig;
  howManyYearsOfExperienceDoYouHave: MultipleChoiceScreenConfig;
  howManyTransactionsClosed: TextInputScreenConfig;
  rbcMember: MultipleChoiceScreenConfig;
  rbcNumber: TextInputScreenConfig;
  rbcInfoScreen: ReadOnlyScreenConfig;
  rbcDisclaimer: ReadOnlyScreenConfig;
  brokerageInformation: TextInputScreenConfig;
  areYouSigningUpAsTeam: MultipleChoiceScreenConfig;
  signingUpAsATeamSplashPage: ImageScreenConfig;
  teamInformation: TextInputScreenConfig;
  leadReceiver: TextInputScreenConfig;
  teamMembers: TextInputScreenConfig;
  doesYourTeamHaveISA: MultipleChoiceScreenConfig;
  howManyISAsWork: MultipleChoiceScreenConfig;
  howManyISAsReceiveLeads: MultipleChoiceScreenConfig;
  propertyTypes: MultipleChoiceScreenConfig;
  additionalLanguagePreference: MultipleChoiceScreenConfig;
  thankYouScreen: ThankYouScreenConfig;
}

type Screens = keyof typeof usScreenOrder | keyof typeof canadaScreenOrder;

export type ScreenOrder = UsScreenOrder | CanadaScreenOrder;
