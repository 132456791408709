//ATTENTION: This file is a slightly modified version of a file from agent-tools... but for the most part its just agent tools.

import {
  FormControl,
  IFormControlContext,
  IFormControlProps,
  InputHelperText,
  InputLabel,
  FormItem,
} from '@ojolabs/form-helpers';
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { convertToE164, getCountryCode } from '../utils';
import { Flexbox } from '@ojolabs/layout';
import { Typography } from '@ojolabs/typography';
import styled from 'styled-components';

export const PHONE_NUMBER_MASK = [
  '(',
  /[2-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const PHONE_NUMBER_PATTERN = '^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$';

const StyledMaskedInput = styled(MaskedInput)`
  border-radius: 8px;
  font-size: 16px;
  font-family: 'DM Sans';
`;

interface MaskedPhoneInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'name' | 'id' | 'pattern' | 'onChange' | 'size'
  > {
  shouldConvertToE164?: boolean;
  onChange: (phone: string) => void;
  value: string;
}
export const MaskedPhoneInput: React.FC<MaskedPhoneInputProps> = ({
  shouldConvertToE164 = false,
  onChange,
  value,
  ...otherProps
}) => {
  const code = getCountryCode(value);
  return (
    <StyledMaskedInput
      {...otherProps}
      mask={PHONE_NUMBER_MASK}
      pattern={PHONE_NUMBER_PATTERN}
      id={'phone'}
      type={'tel'}
      name={'phone'}
      guide={false}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const phone = shouldConvertToE164
          ? convertToE164(code, e.target.value)
          : e.target.value;
        onChange(phone);
      }}
      value={value.replace(code, '')}
      data-test="phone-masked-input"
    />
  );
};

export interface PhoneInputProps extends MaskedPhoneInputProps {
  placeholder: string;
  disabled?: boolean;
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  placeholder,
  value,
  disabled,
  ...otherProps
}) => {
  const code = getCountryCode(value);

  return (
    <div className="phoneInputWrapper">
      <MaskedPhoneInput
        {...otherProps}
        // When updating this input to accept phones from other countries we should probably review the pattern and mask
        placeholder={placeholder}
        className="input--without-horizontal-padding font-normal"
        style={{ paddingLeft: '3.5rem' }}
        disabled={disabled}
        value={value}
      />
      <label id="countryCode" htmlFor="phone" className="phoneInputLabel">
        <span>{code}</span>
        <span className="verticalDivider" />
      </label>
    </div>
  );
};

// Foundation version
export interface TelephoneInputProps
  extends IFormControlContext,
    Pick<IFormControlProps, 'space' | 'className'>,
    MaskedPhoneInputProps {
  label?: string;
  helperText?: string;
}

export const StyledTypography = styled(Typography)`
  margin: 7.5px;
`;

export const FlexboxWithBorder = styled(Flexbox)`
  border-right: solid 1px #dddddd;
`;

export const TelephoneInput: React.FC<TelephoneInputProps> = props => {
  const {
    className,
    disabled,
    space,
    state,
    size,
    fluid,
    label,
    helperText,
    ...inputProps
  } = props;
  const code = getCountryCode(inputProps.value);
  return (
    <FormControl
      className={className}
      disabled={disabled}
      space={space}
      state={state}
      size={size}
      fluid={fluid}
    >
      {!!label && <InputLabel>{label}</InputLabel>}
      <FormItem>
        <FlexboxWithBorder
          borderRightWidth={'1px'}
          borderRightColor={'gray300'}
          pr={2}
          mr={2}
        >
          <StyledTypography size={size}>{code}</StyledTypography>
        </FlexboxWithBorder>
        <MaskedPhoneInput
          {...inputProps}
          data-test="phone-masked-input"
          style={{ borderWidth: 0, width: '100%' }}
          disabled={disabled}
        />
      </FormItem>
      {!!helperText && <InputHelperText>{helperText}</InputHelperText>}
    </FormControl>
  );
};

PhoneInput.displayName = 'PhoneInput';
