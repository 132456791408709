import {
  initialState,
  InitialStateType,
  MultipleChoiceState,
  TextInputState,
} from '../initialState';
import {
  FullRequestDataFields,
  Reference,
  REFERENCE_OTHER_VALUE,
  AgentRole,
  Experience,
  PropertyTypes,
  AdditionalLanguages,
} from './types';
import { MULTIPLE_CHOICE_OTHER_ID } from '../Config/flowTypes';

export const convertRequestFieldsToState = (
  request: FullRequestDataFields
): InitialStateType => {
  return {
    ...initialState,
    ...(request.referredBy && {
      howDidYouHearAboutOjo: referredByToState(
        request.referredBy,
        request.referenceName ? request.referenceName : ''
      ),
      howDidYouHearAboutOjoCanada: referredByToState(
        request.referredBy,
        request.referenceName ? request.referenceName : ''
      ),
    }),
    ...(request.referenceName && {
      whoReferredYou: referenceNameToState(request.referenceName),
    }),
    ...(request.agentRole && {
      whichOfTheFollowingDescribesYou: agentRoleToState(request.agentRole),
    }),
    ...(request.experience && {
      howManyYearsOfExperienceDoYouHave: experienceToState(request.experience),
    }),
    ...(request.qtyTransactions && {
      howManyTransactionsClosed: quantityOfTransactionToState(
        request.qtyTransactions
      ),
    }),
    ...(request.licenseId &&
      request.licenseState && {
        realEstateInformation: licenseToState(
          request.licenseId,
          request.licenseState
        ),
      }),
    ...(request.brokerageName &&
      request.brokerageAddress &&
      request.brokerageEmail &&
      request.brokerName &&
      request.brokeragePhone && {
        brokerageInformation: brokerageToState(
          request.brokerageName,
          request.brokerageAddress,
          request.brokerageEmail,
          request.brokerName,
          request.brokeragePhone.replace('+1', '')
        ),
      }),
    ...((request.inTeam === true || request.inTeam === false) && {
      areYouSigningUpAsTeam: inTeamToState(request.inTeam),
    }),
    ...(request.propertyTypes && {
      propertyTypes: propertyTypesToState(
        request.propertyTypes,
        request.otherPropertyTypes ? request.otherPropertyTypes : ''
      ),
    }),
    ...(request.language && {
      additionalLanguagePreference: languagesToState(
        request.language,
        request.otherLanguages
      ),
    }),
    ...(request.email &&
      request.firstName &&
      request.lastName &&
      request.licenseState &&
      request.phone &&
      request.state && {
        contactInfo: contactInfoToState(
          request.firstName,
          request.lastName,
          request.phone,
          request.email,
          request.licenseState,
          request.licenseId ?? null
        ),
      }),
    ...((request.consentChecked === true ||
      request.consentChecked === false) && {
      consentPage: consentToState(request.consentChecked),
    }),
    ...(request.rbcMember &&
      typeof request.rbcMember === 'string' && {
        rbcMember: rbcMemberToState(request.rbcMember),
      }),
    ...(request.rbcNumber && {
      rbcNumber: rbcNumberToState(request.rbcNumber),
    }),
    ...(request.teamMembers &&
      request.teamName && {
        teamInformation: teamInformationToState(
          request.teamMembers,
          request.teamName
        ),
      }),
    ...(request.leadReceiverData && {
      leadReceiver: leadReceiverToState(request.leadReceiverData),
    }),
    ...(request.teamMembersData && {
      teamMembers: teamMembersToState(request.teamMembersData),
    }),
    ...(request.hasIsa && {
      doesYourTeamHaveISA: hasIsaToState(request.hasIsa),
    }),
    ...(request.isaNumber && {
      howManyISAsWork: isaWorkersNumberToState(request.isaNumber),
    }),
    ...(request.ojoIsaNumber && {
      howManyISAsReceiveLeads: isaWorkersNumberToState(request.ojoIsaNumber),
    }),
  } as InitialStateType;
};

const isaWorkersNumberToState = (isaNumber: string): MultipleChoiceState => {
  return {
    selected: [convertSalesforceValueToStateValue(isaNumber)] as string[],
    otherFreeformText: '',
  };
};

const hasIsaToState = (value: boolean): MultipleChoiceState => {
  return {
    selected: [value ? 'Yes' : 'No'] as string[],
    otherFreeformText: '',
  };
};

const teamMembersToState = (teamMembersData: string): TextInputState => {
  return {
    fields: {
      teamMembers: {
        value: teamMembersData,
        isInvalidInput: false,
      },
    },
  };
};

const leadReceiverToState = (leadReceiverList: string): TextInputState => {
  return {
    fields: {
      leadReceiverData: {
        value: leadReceiverList,
        isInvalidInput: false,
      },
    },
  } as TextInputState;
};

const teamInformationToState = (
  teamMembers: number,
  teamName: string
): TextInputState => {
  return {
    fields: {
      teamMembers: {
        value: String(teamMembers),
        isInvalidInput: false,
      },
      teamName: {
        value: teamName,
        isInvalidInput: false,
      },
    },
  };
};

const rbcNumberToState = (rbcNumber: string): TextInputState => {
  return {
    fields: {
      rbcNumber: {
        value: rbcNumber,
        isInvalidInput: false,
      },
    },
  };
};

const rbcMemberToState = (isMember: string): MultipleChoiceState => {
  return {
    selected: [isMember] as string[],
    otherFreeformText: '',
  };
};

const consentToState = (consentChecked: boolean): MultipleChoiceState => {
  return {
    selected: [consentChecked ? 'iConsent' : 'iDoNotConsent'] as string[],
    otherFreeformText: '',
  };
};

const contactInfoToState = (
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  province: string,
  provincialLicenseId: string | null
): TextInputState => {
  return {
    fields: {
      firstName: {
        value: firstName,
        isInvalidInput: false,
      },
      lastName: {
        value: lastName,
        isInvalidInput: false,
      },
      phone: {
        value: phone,
        isInvalidInput: false,
      },
      email: {
        value: email,
        isInvalidInput: false,
      },
      province: {
        value: province,
        isInvalidInput: false,
      },
      provincialLicenseId: {
        value: provincialLicenseId ?? '',
        isInvalidInput: false,
      },
    },
  } as TextInputState;
};

const languagesToState = (
  language: AdditionalLanguages,
  otherProperties: string | null | undefined
): MultipleChoiceState => {
  return {
    selected: [convertSalesforceValueToStateValue(language)] as string[],
    otherFreeformText: otherProperties ? otherProperties : '',
  } as MultipleChoiceState;
};

const propertyTypesToState = (
  types: PropertyTypes[],
  otherProperties: string | null
): MultipleChoiceState => {
  const stateTypes: string[] = types.map(type =>
    convertSalesforceValueToStateValue(type)
  );
  return {
    selected: stateTypes as string[],
    otherFreeformText: otherProperties ? otherProperties : '',
  } as MultipleChoiceState;
};

const inTeamToState = (inTeam: boolean): MultipleChoiceState => {
  return {
    selected: [inTeam === true ? 'Yes' : 'No'] as string[],
    otherFreeformText: '',
  } as MultipleChoiceState;
};

const brokerageToState = (
  brokerageName: string,
  brokerageAddress: string,
  brokerageEmail: string,
  brokerName: string,
  brokeragePhone: string
): TextInputState => {
  return {
    fields: {
      brokerageName: {
        value: brokerageName,
        isInvalidInput: false,
      },
      brokerageAddress: {
        value: brokerageAddress,
        isInvalidInput: false,
      },
      brokerName: {
        value: brokerName,
        isInvalidInput: false,
      },
      brokerageEmail: {
        value: brokerageEmail,
        isInvalidInput: false,
      },
      brokeragePhone: {
        value: `+${brokeragePhone}`,
        isInvalidInput: false,
      },
    },
  } as TextInputState;
};

const licenseToState = (
  licenseId: string,
  licenseState: string
): TextInputState => {
  return {
    fields: {
      licenseId: {
        value: licenseId,
        isInvalidInput: false,
      },
      licenseState: {
        value: licenseState,
        isInvalidInput: false,
      },
    },
  } as TextInputState;
};

const referredByToState = (
  selected: Reference,
  otherInput: string | null
): MultipleChoiceState => {
  const isOtherSelected = REFERENCE_OTHER_VALUE === selected;
  const stateValue = convertSalesforceValueToStateValue(selected);
  return {
    selected: [stateValue],
    otherFreeformText: isOtherSelected ? otherInput : '',
  } as MultipleChoiceState;
};

const referenceNameToState = (value: string): TextInputState => {
  const name = convertSalesforceValueToStateValue(value);
  return {
    fields: {
      referenceName: {
        value: name,
        isInvalidInput: false,
      },
    },
  } as TextInputState;
};

const agentRoleToState = (role: AgentRole): MultipleChoiceState => {
  const stateValue = convertSalesforceValueToStateValue(role);
  return {
    selected: [stateValue],
    otherFreeformText: '',
  } as MultipleChoiceState;
};

const experienceToState = (experience: Experience): MultipleChoiceState => {
  return {
    selected: [experience],
    otherFreeformText: '',
  } as MultipleChoiceState;
};

const quantityOfTransactionToState = (value: string): TextInputState => {
  return {
    fields: {
      qtyTransactions: {
        value: value,
        isInvalidInput: false,
      },
    },
  } as TextInputState;
};

const salesforceValuesToUserValues: Record<string, string> = {
  INDIVIDUAL_AGENT: 'Individual Agent',
  ISA_ON_A_TEAM: 'ISA on a team',
  TEAM_LEAD_AND_RAINMAKER: 'Team Lead and Rainmaker',
  SINGLE_FAMILY_HOMES: 'Single Family Homes',
  MOBILE_MANUFACTURED: 'Mobile / Manufactured',
  CONDOS_TOWNHOMES: 'Condos / Townhomes',
  RBC: 'Invited by Royal Bank of Canada',
  ANOTHER_AGENT: 'Referred by another agent',
  OTHER_REFERENCE: 'Other',
  CONTACTED_BY_OJO: 'Contacted by OJO',
  RELOCATION_DIRECTOR: 'Relocation Director',
  EMAIL_MARKETING: 'Email Marketing',
  OTHER_PROPERTY: MULTIPLE_CHOICE_OTHER_ID,
  ONE: '1',
  TWO_THREE: '2-3',
  FOUR_FIVE: '4-5',
  SIX_TEN: '6-10',
  ELEVEN_PLUS: '11+',
  DONT_KNOW: "Don't Know",
};

const convertSalesforceValueToStateValue = (value: string) => {
  // the vast majority of values can just be computed by uppercasing and replacing spaces with underscores,
  // but we have a dictionary for those that can not
  if (value in salesforceValuesToUserValues) {
    return salesforceValuesToUserValues[
      value as keyof typeof salesforceValuesToUserValues
    ];
  } else {
    return convertToConstString(value);
  }
};

//eg - "CONTACTED_BY_OJO" to "Contacted by OJO"
const convertToConstString = (s: string) =>
  s[0] + s.slice(1).toLowerCase().replace(/_/g, ' ');
