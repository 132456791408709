import styled, { css } from 'styled-components';
import { defaultTheme } from '../../theme';
import PropTypes from 'prop-types';

export interface InputThemedProps {
  state?: string;
}

export const InputThemed = styled.input<InputThemedProps>`
  flex-direction: column;
  justify-content: center;
  align-items: start;

  width: ${props => props.width ?? '100%'};

  background-color: ${defaultTheme.colors.white};
  font-size: ${defaultTheme.fontSizes.md} !important;
  font-weight: ${defaultTheme.fontWeights.regular} !important;
  color: ${defaultTheme.colors.gray30};
  box-shadow: none !important;
  border: 1px solid ${defaultTheme.colors.border1};

  /*default state*/
  ${props => props.state === undefined && css``}

  /* success status */
    ${props =>
    props.state === 'success' &&
    css`
      border: 1px solid ${defaultTheme.colors.green50} !important;
      color: ${defaultTheme.colors.gray70};
    `}

        /* alert status class on div */
    ${props =>
    props.state === 'alert' &&
    css`
      border: 1px solid ${defaultTheme.colors.red50} !important;
      color: ${defaultTheme.colors.gray70};
    `}

  padding: 0.5rem 0.75rem;
  border-radius: ${defaultTheme.radii.sm};

  &:disabled {
    background-color: ${defaultTheme.colors.gray10} !important;
    border: 1px solid ${defaultTheme.colors.gray10};
    color: ${defaultTheme.colors.gray50};
    opacity: unset;
  }

  &:not([disabled]) {
    color: ${defaultTheme.colors.gray70};
    &:hover {
      border: 1px solid ${defaultTheme.colors.emerald60};
      color: ${defaultTheme.colors.gray70};
    }
    &:active {
      border: 1px solid ${defaultTheme.colors.emerald60};
      color: ${defaultTheme.colors.gray70};
    }
    &:focus {
      border: 1px solid ${defaultTheme.colors.emerald50};
      border: 1px solid ${defaultTheme.colors.emerald50};
      box-shadow: 0 0 0 2px ${defaultTheme.colors.emerald50} !important;
      color: ${defaultTheme.colors.gray70};
    }
  }
`;

InputThemed.propTypes = {
  state: PropTypes.oneOf(['error', 'valid']),
};
