import * as Sentry from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import GitInfo from 'react-git-info/macro';
import { Environment } from '../AgentRecruiting/utils';

const SENTRY_DSN =
  'https://3dafeb6411b24aae95462e63f5ed58dd@o213772.ingest.sentry.io/6375073';

const sentryIntegrations = [new ExtraErrorData()];

const gitInfo = GitInfo();

export const initializeSentry = (env: Environment) => {
  if (env !== 'local') {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: env,
      release: `${gitInfo.branch}-${gitInfo.commit.hash}`,
      integrations: sentryIntegrations,
    });
  }
};
