import { Typography } from '@ojolabs/typography';
import React from 'react';
import { PageContainer } from '../Components/PageContainer';
import { Box, Center, HorizontalVerticalCenter, Stack } from '@ojolabs/layout';
import { NextStepCard } from '../Components/NextStepCard';
import headerLogoSvg from '../../../assets/images/img_app-submitted.svg';
import { ThankYouScreenConfig } from '../Config/flowTypes';
import { defaultTheme } from '../../theme';
import { FontIcon } from '../Components/FontIcon';

export interface ThankYouScreenConfigProps {
  config: ThankYouScreenConfig;
}

const HeaderLogo = <img src={headerLogoSvg} alt={'checklist'} />;

export const ThankYouPage: React.FC<ThankYouScreenConfigProps> = ({
  config,
}) => {
  const iconColor = defaultTheme.colors.emerald50;

  const pulseIconCentered = (
    <HorizontalVerticalCenter borderRadius={'circle'} bg={'emerald10'}>
      <FontIcon
        iconName={'check-circle-outside'}
        style={{ color: iconColor, fontSize: '1rem' }}
      />
    </HorizontalVerticalCenter>
  );

  const houseIconCentered = (
    <HorizontalVerticalCenter borderRadius={'circle'} bg={'emerald10'}>
      <FontIcon
        iconName={'property-light'}
        style={{ color: iconColor, fontSize: '1rem' }}
      />
    </HorizontalVerticalCenter>
  );

  return (
    <PageContainer>
      <Stack>
        <Center centerChildren={true} marginTop={'3.25rem'} mb={'std32'}>
          {HeaderLogo}
        </Center>

        <Center centerChildren={true}>
          <Typography weight={'bold'} color={'emerald50'} mb={'std32'}>
            {'Thank you!'}
          </Typography>
          <Typography
            size={'4xl'}
            weight={'bold'}
            textAlign={'center'}
            mt={0}
            mb={'std32'}
          >
            {'Your application has been submitted!'}
          </Typography>
          <Typography mb={0}>
            {
              'Once we’ve had a chance to fully review your submission and confirm availability in your area, someone from our team will be in touch. '
            }
          </Typography>
          <Typography mb={'std32'}>
            {
              'Acceptance into the Movoto Select Network is subject to availability in one or more of the areas you’ve indicated you’re able to service.'
            }
          </Typography>
          <Typography color={'emerald50'} weight={'bold'} mb={'std32'}>
            {'Next Steps:'}
          </Typography>
        </Center>

        <Box paddingBottom={'1.5rem'}>
          <NextStepCard
            title={'Check Application Status'}
            description={
              'Click here to view more details about where your application is in the process.'
            }
            icon={pulseIconCentered}
            onClick={() => {
              window.open('https://applicationstatus.ojo.com/', '_self');
            }}
          />
        </Box>

        <Box>
          <NextStepCard
            title={'Learn more about the program'}
            description={
              'Learn more about how Movoto is uniquely positioned to help agents win.'
            }
            icon={houseIconCentered}
            onClick={() => {
              window.open(config.learnMoreUrl, '_self');
            }}
          />
        </Box>
      </Stack>
    </PageContainer>
  );
};
