import { defaultTheme } from '../../theme';
import { CSSObject } from 'styled-components';

const DROPDOWN_BASE_BORDER_RADIUS = defaultTheme.radii.sm;
export const customControl = (
  base: CSSObject,
  // tslint:disable-next-line: no-any
  state: any,
  isValid: boolean = true
) => {
  const { menuIsOpen, hasBoxShadow, maxWidth, inputValue } =
    state.selectProps!!;
  const borderRadius =
    menuIsOpen && (hasBoxShadow || inputValue)
      ? `${DROPDOWN_BASE_BORDER_RADIUS} ${DROPDOWN_BASE_BORDER_RADIUS} 0 0`
      : DROPDOWN_BASE_BORDER_RADIUS;

  return {
    ...base,
    borderRadius,
    boxShadow:
      menuIsOpen && hasBoxShadow
        ? `0 0 0 2px ${defaultTheme.colors.emerald50}`
        : 'none',
    border: `1px solid ${
      isValid ? defaultTheme.colors.border1 : defaultTheme.colors.red50
    }`,
    ...(menuIsOpen && (hasBoxShadow || inputValue) && { borderBottom: 0 }),
    paddingTop: 0,
    '&:focus, &:hover': {
      cursor: 'pointer',
      border: `1px solid ${
        isValid ? defaultTheme.colors.emerald50 : defaultTheme.colors.red50
      }`,
    },
    ...(maxWidth && { maxWidth }),
  };
};

export const customOption = (
  base: CSSObject,
  // tslint:disable-next-line: no-any
  state: any
) => {
  const { isDisabled, isFocused, isSelected } = state;

  return {
    ...base,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? defaultTheme.colors.gray20
      : isFocused
      ? undefined
      : undefined,
    font: `${defaultTheme.fonts.default} !important`,
    color: defaultTheme.colors.defaultText,
    '&:hover': {
      backgroundColor: defaultTheme.colors.gray10,
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  };
};

export const dropdownStyleOverridesThemed = (isValid: boolean) => {
  return {
    // tslint:disable-next-line: no-any
    control: (base: CSSObject, state: any) =>
      customControl(base, state, isValid),
    // tslint:disable-next-line: no-any
    option: (base: CSSObject, state: any) => customOption(base, state),
    input: (styles: CSSObject) => ({ ...styles }),
    placeholder: (styles: CSSObject) => ({
      ...styles,
    }),
    singleValue: (styles: CSSObject) => ({
      ...styles,
      fontFamily: defaultTheme.fonts.default,
    }),
  };
};
