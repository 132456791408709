import React, { useState } from 'react';
import classNames from 'classnames';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { Typography } from '@ojolabs/typography';
import { defaultTheme } from '../../theme';
import { Flexbox } from '@ojolabs/layout';
import styled from 'styled-components';

const InputThemedContainer = styled(Flexbox)`
  flex-direction: column;
  justify-content: center;
  align-items: start;

  /*default state*/
  background-color: ${defaultTheme.colors.background3};
  border: 1px solid ${defaultTheme.colors.gray20};
  border-radius: ${defaultTheme.radii.sm};
  font-size: ${defaultTheme.fontSizes.md};
  font-weight: ${defaultTheme.fontWeights.bold};
  color: ${defaultTheme.colors.gray30};
  padding-left: ${defaultTheme.space.std8};

  min-height: 40px;

  /* disabled class on div */
  &.disabled {
    background-color: ${defaultTheme.colors.gray10};
    border: 1px solid ${defaultTheme.colors.gray10};
    color: ${defaultTheme.colors.gray50};
    opacity: unset;
  }

  /* focus class on div */
  &.focus {
    border: 1px solid ${defaultTheme.colors.emerald50};
    box-shadow: 0 0 0 2px ${defaultTheme.colors.emerald50} !important;
    color: ${defaultTheme.colors.gray70};
  }

  /* success status class on div */
  &.success {
    border: 1px solid ${defaultTheme.colors.green50} !important;
    color: ${defaultTheme.colors.gray70};
  }

  /* alert status class on div */
  &.alert {
    border: 1px solid ${defaultTheme.colors.red50} !important;
    color: ${defaultTheme.colors.gray70};
  }

  &:disabled {
    background-color: ${defaultTheme.colors.gray20} !important;
    border: 1px solid ${defaultTheme.colors.gray50};
    color: ${defaultTheme.colors.gray50};
    opacity: unset;
  }

  &:not([disabled]) {
    color: ${defaultTheme.colors.gray70};
    &:hover {
      border: 1px solid ${defaultTheme.colors.emerald50};
      color: ${defaultTheme.colors.gray70};
    }
    &:active {
      border: 1px solid ${defaultTheme.colors.emerald50};
      color: ${defaultTheme.colors.gray70};
    }
    &:focus {
      border: 1px solid ${defaultTheme.colors.emerald50};
      color: ${defaultTheme.colors.gray70};
    }
  }

  /*input child config*/
  & input {
    width: 100%;
    border: none !important;
    box-shadow: none !important;
    font-weight: ${defaultTheme.fontWeights.regular};
    font-size: ${defaultTheme.fontSizes.md};
  }
`;

/**
 * Areas to improve
 * animation when isFocused turns true
 * when disabled true be able to show a value
 */

type Status = 'success' | 'alert';

export interface MaskedInputThemedProps extends MaskedInputProps {
  label?: string;
  status?: Status;
}

export const MaskedInputThemed: React.FC<MaskedInputThemedProps> = ({
  value,
  disabled,
  status,
  mask,
  type,
  name,
  placeholder,
  label,
  required,
  onChange,
}) => {
  const [internalValue, setInternalValue] = useState(value?.toString() ?? '');

  const isEmpty = internalValue.toString().length === 0;

  const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
    onChange && onChange(e);
  };

  const [isInputFocused, setIsInputFocused] = useState(false);

  const onFocusInput = () => {
    setIsInputFocused(true);
  };

  const onBlurInput = () => {
    setIsInputFocused(false);
  };

  return (
    <InputThemedContainer
      className={classNames({
        input: true,
        focus: isInputFocused,
        disabled: disabled,
        success: status && status === 'success',
        alert: status && status === 'alert',
      })}
    >
      {(!isEmpty || isInputFocused) && label && (
        <Typography
          as={'label'}
          size={'xs'}
          weight={'bold'}
          style={{ position: 'relative' }}
        >
          {label}
        </Typography>
      )}
      <MaskedInput
        value={internalValue}
        mask={mask || []}
        type={type}
        name={name}
        placeholder={isInputFocused ? undefined : placeholder}
        guide={false}
        id={name}
        onChange={onChangeInternal}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        required={required}
        disabled={disabled}
      />
    </InputThemedContainer>
  );
};
