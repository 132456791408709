import React from 'react';
import { ButtonIconContainer, ButtonSizes } from '@ojolabs/button';
import { Box, Flexbox, Stack } from '@ojolabs/layout';
import IconEllipsisHorizontal from '@ojolabs/react-icons/ellipsis-horizontal';
import { splitOnIndex } from '../utils';
import { Checkbox } from '@ojolabs/checkbox';

export interface CheckboxSelectOption {
  id: string;
  label: string;
  disabled?: boolean;
  onSelectCustom?: (id?: string) => void;
  hideDots?: boolean;
}

export const CheckboxSelectContainer: React.FC = ({ children }) => (
  <Stack space={2}>{children}</Stack>
);
CheckboxSelectContainer.displayName = 'CheckboxSelectContainer';

export interface CheckboxSelectProps {
  options: Readonly<Array<CheckboxSelectOption>>;
  selected?: string | Array<string>;
  onSelect(id: string): void;
  size?: ButtonSizes;
  splitOnIndex?: number;
}

export const checkboxButtonsMapper =
  ({
    selected,
    onSelect,
    size,
  }: Pick<CheckboxSelectProps, 'selected' | 'size' | 'onSelect'>) =>
  ({ id, label, onSelectCustom, hideDots }: CheckboxSelectOption) => {
    const isSelected =
      (Array.isArray(selected) && selected.includes(id)) || selected === id;

    return (
      <Box data-test={id} key={id}>
        <Flexbox alignItems="center">
          <Checkbox
            checked={isSelected}
            onChange={() => {
              onSelectCustom ? onSelectCustom(id) : onSelect(id);
            }}
          />
          <Box as="span" ml={2}>
            {label}
          </Box>
        </Flexbox>
        {onSelectCustom && !hideDots && (
          <ButtonIconContainer size={size} position="right">
            <IconEllipsisHorizontal />
          </ButtonIconContainer>
        )}
      </Box>
    );
  };

export const CheckboxSelect: React.FC<CheckboxSelectProps> = ({
  options,
  selected,
  onSelect,
  size = 'md',
  splitOnIndex: index = 0,
}) => {
  const [first] = splitOnIndex(index, options);

  return (
    <Box>
      <CheckboxSelectContainer>
        {first.map(checkboxButtonsMapper({ selected, onSelect, size }))}
      </CheckboxSelectContainer>
    </Box>
  );
};

CheckboxSelect.displayName = 'CheckboxSelect';
