import * as React from 'react';
import { Flexbox } from '@ojolabs/layout';
import { Typography } from '@ojolabs/typography';
import logoBlueCircle from '../../../assets/images/ojo-logo-blue.svg';
import { Link } from '@ojolabs/link';

export const MaintenanceScreen: React.FC = () => {
  return (
    <Flexbox
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems="center"
      margin={'54px'}
    >
      <Flexbox flexDirection={'column'} justifyContent={'center'}>
        <img
          data-testid="ojo-logo"
          height={'48'}
          src={logoBlueCircle}
          alt="OJO Logo"
        />
        <Typography as="p" color={'grey'}>
          Uh oh!
        </Typography>
      </Flexbox>
      <Typography weight="bold" as="h1" size="2xl">
        Our application form is currently unavailable.
      </Typography>

      <Typography as="h3" size="md" textAlign={'center'}>
        Please call us and we’ll be happy to assist you with your application.
      </Typography>

      <Typography as="p" size="md" textAlign={'center'}>
        For agents in the <b>United States</b>, call: <br />
        <Link href="tel:8669731703">(866) 973-1703</Link>
      </Typography>

      <Typography as="p" size="md" textAlign={'center'}>
        For agents in <b>Canada</b>, call: <br />
        <Link href="tel:8669731703">(866) 973-1703</Link>
      </Typography>

      <Typography as="p" size="md" textAlign={'center'}>
        We look forward to assisting you with becoming a part of our network.
      </Typography>
    </Flexbox>
  );
};
