import { getEnv, STAGING, PRODUCTION } from './utils';

export const getConfig = () => {
  const env = getEnv();
  let authorizationKey;

  switch (env) {
    case STAGING:
      authorizationKey = 'i845miq8c930bmnfartr8ftr92jcfuoltlb8';
      break;
    case PRODUCTION:
      authorizationKey = 'nnvnrsed34vr6rvff1clouaqvlqb2h69g6hf';
      break;
    default:
      //default to beta key
      authorizationKey = '76fovabhjgriaprf53pfhvcigraon1t2fq05';
      break;
  }

  return {
    core: {
      authorizationKey,
      //currently we are doing a static ID since our initial use case is just a feature flag. Further down the line when we do A/B testing, we'll want to initialize this key with the user's sessionId;s
      key: 'STATIC_ID',
    },
  };
};
