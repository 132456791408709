import React, { ChangeEvent, FocusEvent } from 'react';
import {
  STATE_DROPDOWN,
  NUMBER,
  MULTI_LINE,
  EMAIL,
  PHONE,
  PROVINCE_DROPDOWN,
} from '../Config/flowTypes';
import { MultiLineTextField } from '@ojolabs/text-field';
import { Typography } from '@ojolabs/typography';
import { Dropdown } from '@ojolabs/dropdown';
import { TextInputScreenConfig } from '../Config/flowTypes';
import {
  stateDropdownOptions,
  filterStringToOnlyNumbers,
  isValidSalesforceEmail,
  isPhoneNumber,
  provinceDropdownOptions,
  getCountryCode,
  convertToE164,
} from '../utils';
import { TextInputState } from '../initialState';
import { Box, Flexbox } from '@ojolabs/layout';
import styled from 'styled-components';
import { useAppDispatch } from '../reduxStore';
import { getValidatePhone } from '../API/client';
import { InputThemed } from './TextInput';
import { defaultTheme } from '../../theme';
import { dropdownStyleOverridesThemed } from './DropdownThemed';
import { MaskedInputThemed } from './MaskedInputThemed';
import { PHONE_NUMBER_MASK } from '../GenericComponents/PhoneInput';

const RedText = styled.span`
  color: ${defaultTheme.colors.red50};
`;

export interface TextInputScreenProps {
  config: TextInputScreenConfig;
  matchingState: TextInputState;
  setValueOfField: (
    fieldTitle: string,
    newValue: string,
    isInvalidInput?: boolean
  ) => void;
  showInlineErrors?: boolean;
}

export interface DropdownOption {
  id: string;
  name: string;
  hidden?: boolean;
}

export const TextInputScreen: React.FC<TextInputScreenProps> = ({
  config,
  matchingState,
  setValueOfField,
  showInlineErrors = false,
}) => {
  const { fields, subSectionBody, subSectionTitle } = config;
  const fieldValues = matchingState.fields;
  const dispatch = useAppDispatch();

  const isPhoneNumberValid = async (phoneNumber: string): Promise<boolean> => {
    const response = await dispatch(getValidatePhone(phoneNumber))
      .unwrap()
      .catch(() => null);
    return response?.isValidPhone ?? true;
  };

  return (
    <Flexbox
      flexDirection={'row'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
    >
      {fields.map(
        (
          {
            title,
            type,
            placeholder,
            required = true,
            halfWidth = false,
            id,
            maxLength,
            validatePhone = false,
          },
          index
        ) => {
          const isInvalidInput = fieldValues[id].isInvalidInput;
          const state =
            isInvalidInput && showInlineErrors ? 'error' : undefined;

          return (
            <Box
              key={title}
              data-testid={id}
              width={halfWidth ? 0.48 : 1}
              mt={index > 0 ? 'std24' : ''}
            >
              <Typography size={'sm'} weight={'bold'} mb={'std2'}>
                {title} {required && <RedText>*</RedText>}
              </Typography>
              {type === MULTI_LINE && (
                <MultiLineTextField
                  state={state}
                  fluid="true"
                  value={fieldValues[id].value}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;
                    setValueOfField(id, newValue);
                  }}
                />
              )}
              {/* todo: change this to masked input
              https://ojolabs.atlassian.net/browse/CN-4115 */}
              {type === NUMBER && (
                <InputThemed
                  state={state}
                  value={fieldValues[id].value}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const onlyNumbers = filterStringToOnlyNumbers(
                      e.target.value
                    );
                    setValueOfField(id, onlyNumbers);
                  }}
                />
              )}
              {type === STATE_DROPDOWN && (
                <Dropdown
                  data-test="state-dropdown"
                  value={{
                    id: fieldValues[id].value,
                    name: fieldValues[id].value,
                  }}
                  defaultValue={{
                    id: 'Alabama',
                    name: 'Alabama',
                  }}
                  isSearchable={false}
                  options={stateDropdownOptions}
                  placeholder={placeholder ?? 'Choose State'}
                  onChange={(option: DropdownOption) => {
                    setValueOfField(id, option.id);
                  }}
                  styleOverrides={dropdownStyleOverridesThemed(true)}
                />
              )}
              {type === PROVINCE_DROPDOWN && (
                <Dropdown
                  data-test="province-dropdown"
                  value={{
                    id: fieldValues[id].value,
                    name: fieldValues[id].value,
                  }}
                  defaultValue={{
                    id: 'Alberta',
                    name: 'Alberta',
                  }}
                  isSearchable={false}
                  options={provinceDropdownOptions}
                  placeholder="Choose Province"
                  onChange={(option: DropdownOption) => {
                    setValueOfField(id, option.id);
                  }}
                  styleOverrides={dropdownStyleOverridesThemed(true)}
                />
              )}
              {type === undefined && (
                <InputThemed
                  state={state}
                  value={fieldValues[id].value}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValueOfField(id, e.target.value);
                  }}
                />
              )}
              {type === EMAIL && (
                <>
                  <InputThemed
                    state={state}
                    value={fieldValues[id].value}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValueOfField(
                        id,
                        e.target.value.toLocaleLowerCase(),
                        !isValidSalesforceEmail(
                          e.target.value.toLocaleLowerCase()
                        )
                      );
                    }}
                  />
                  {isInvalidInput && showInlineErrors && (
                    <Typography size={'xs'} color={'textNegative'} mt={'std2'}>
                      {'Please enter a valid email address'}
                    </Typography>
                  )}
                </>
              )}

              {type === PHONE && (
                <>
                  <MaskedInputThemed
                    status={state === 'error' ? 'alert' : undefined}
                    value={fieldValues[id].value}
                    placeholder={placeholder}
                    onChange={e => {
                      const code = getCountryCode(e.target.value);
                      const num = convertToE164(code, e.target.value);
                      setValueOfField(id, num, !isPhoneNumber(num));
                    }}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                      if (validatePhone) {
                        isPhoneNumberValid(e.target.value).then(isValid => {
                          if (!isValid) {
                            setValueOfField(id, e.target.value, true);
                          }
                        });
                      }
                    }}
                    mask={PHONE_NUMBER_MASK}
                  />
                  {isInvalidInput && showInlineErrors && (
                    <Typography size={'xs'} color={'textNegative'} mt={'std2'}>
                      {
                        'Invalid phone number. Please use a mobile phone number.'
                      }
                    </Typography>
                  )}
                </>
              )}
            </Box>
          );
        }
      )}
      {subSectionTitle && (
        <Typography textStyleVariant={'smallBold'} mt={'std24'}>
          {subSectionTitle}
        </Typography>
      )}
      {subSectionBody && (
        <Typography
          textStyleVariant={'smallRegular'}
          mt={'std8'}
          style={{ whiteSpace: 'pre-line' }}
        >
          {subSectionBody}
        </Typography>
      )}
    </Flexbox>
  );
};
