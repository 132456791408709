import * as React from 'react';
import {
  connectSplit,
  selectTreatmentValue,
  initSplitSdk,
  getTreatments,
} from '@splitsoftware/splitio-redux';
import { useParams } from 'react-router-dom';
import { ISplitState } from '@splitsoftware/splitio-redux/lib/types';
import { useAppDispatch } from '../reduxStore';
import { getConfig } from '../split';
import { store } from '../reduxStore';
import { MaintenanceScreen } from './MaintenanceScreen';
import Loading from '../GenericComponents/Loading';
import { getExistingApplicationData } from '../initialLoadHelpers';
import { useSelector } from 'react-redux';
import { selectGetApplicationFieldsStatus } from '../slices/agentApplicationSlice';
import { ExpectedQueryParams, LOADING, SUCCESS } from '../API/types';
import { CountrySelect } from './CountrySelect';
import { getQueryParams } from '../API/queryParamsToRequest';
import USFlow from './USFlow';

interface Props {
  splitio: ISplitState;
}

export type CountryParam = 'us' | 'ca';

const US_COUNTRY_PARAM: CountryParam = 'us';
const CA_COUNTRY_PARAM: CountryParam = 'ca';

let getApplicationFieldStatusHasLoadedSuccesfully = false;

export const AgentApplication = (props: Props) => {
  const dispatch = useAppDispatch();
  const { splitio } = props;

  const countryParamFromRoute = useParams().country as CountryParam;

  const getCountryParamFromURL = (): CountryParam | null => {
    if (countryParamFromRoute) {
      return countryParamFromRoute;
    }
    const countryParamFromQueryParams = getCountryParamFromQueryParams(
      getQueryParams()
    );
    if (countryParamFromQueryParams) {
      return countryParamFromQueryParams;
    }
    return null;
  };

  const getCountryParamFromQueryParams = (
    queryParams: ExpectedQueryParams | null
  ): CountryParam | null => {
    if (queryParams) {
      if (queryParams.country === 'CANADA') {
        return CA_COUNTRY_PARAM;
      } else {
        return US_COUNTRY_PARAM;
      }
    }
    return null;
  };

  const [countryParam, setCountryParam] = React.useState<null | CountryParam>(
    getCountryParamFromURL()
  );

  const [countryFromQueryParam] = React.useState<null | CountryParam>(
    getCountryParamFromQueryParams(getQueryParams())
  );

  React.useEffect(() => {
    dispatch(initSplitSdk({ config: getConfig() }));
    dispatch(
      getTreatments({
        splitNames: [
          'agent-recruiting_show_maintenance_screen',
          'agent-recruiting_disable-canadian-signups',
        ],
      })
    );
  }, []);

  React.useEffect(() => {
    const getCountryFromSessionId = async () => {
      const existingApp = await getExistingApplicationData(dispatch);
      if (existingApp && existingApp?.data?.country) {
        const country =
          existingApp.data.country === 'CANADA'
            ? CA_COUNTRY_PARAM
            : US_COUNTRY_PARAM;
        setCountryParam(country);
      }
    };
    getCountryFromSessionId();
  }, []);

  React.useEffect(() => {
    if (countryFromQueryParam && countryFromQueryParam !== countryParam) {
      setCountryParam(countryFromQueryParam);
    }
  }, [countryFromQueryParam, countryParam]);

  const getApplicationFieldStatus = useSelector(
    selectGetApplicationFieldsStatus()
  );

  if (getApplicationFieldStatus === SUCCESS) {
    getApplicationFieldStatusHasLoadedSuccesfully = true;
  }

  //we only want to show loading screen here the very first time we getApplicationFieldStatus - so we flag it after the first time
  const showLoadingScreen =
    getApplicationFieldStatus === LOADING &&
    !getApplicationFieldStatusHasLoadedSuccesfully;

  if (showLoadingScreen || !splitio.isReady) {
    return <Loading />;
  }

  const treatment = selectTreatmentValue(
    store.getState().splitio,
    'agent-recruiting_show_maintenance_screen'
  );

  const disableCanadaTreatment = selectTreatmentValue(
    store.getState().splitio,
    'agent-recruiting_disable-canadian-signups'
  );

  if (treatment === 'on') {
    return <MaintenanceScreen />;
  } else {
    if (countryParam === US_COUNTRY_PARAM || disableCanadaTreatment === 'on') {
      return <USFlow />;
    } else {
      return (
        <CountrySelect
          setCountryParam={(countryParam: CountryParam) =>
            setCountryParam(countryParam)
          }
        />
      );
    }
  }
};

export default connectSplit()(AgentApplication);

AgentApplication.displayName = 'AgentApplication';
