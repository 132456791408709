/* This file is directly pulled from agent tools - CN-4026 covers replacing*/

import React from 'react';
import {
  Button,
  ButtonIconContainer,
  ButtonSizes,
  IButtonProps,
} from '@ojolabs/button';
import { Stack, Box, Flexbox } from '@ojolabs/layout';
import styled from 'styled-components';
import IconEllipsisHorizontal from '@ojolabs/react-icons/ellipsis-horizontal';
import IconCheckCircleFill from '@ojolabs/react-icons/check-circle-fill';
import { splitOnIndex } from '../utils';
import { HORIZONTAL, VERTICAL } from '../Config/flowTypes';
import { defaultTheme } from '../../theme';

export interface PillSelectOption {
  id: string;
  label: string;
  disabled?: boolean;
  onSelectCustom?: (id?: string) => void;
  hideDots?: boolean;
  icon?: React.ReactNode;
}

export interface PillSelectProps {
  options: Readonly<Array<PillSelectOption>>;
  selected?: string | Array<string>;
  onSelect(id: string): void;
  size?: ButtonSizes;
  splitOnIndex?: number;
  orientation?: typeof HORIZONTAL | typeof VERTICAL;
}

type IPillButtonProps = IButtonProps & { selected: boolean };

export const PillButton = styled(Button)<IPillButtonProps>`
  justify-content: left;
  font-weight: 400;
  cursor: ${({ disabled }) =>
    disabled ? 'not-allowed !important' : 'pointer'};

  // TODO: remove styles below once new pill component is updated pulled in

  &:focus,
  &:hover {
    box-shadow: none !important;
  }

  ${({ selected }) =>
    !!selected &&
    `
      border-color: transparent;
      background-color: ${defaultTheme.colors.background2};
    `}
`;
PillButton.displayName = 'PillButton';

export const HORIZONTAL_STYLE = {
  flexDirection: `row`,
};

export const LabelBox = styled(Box)`
  text-align: left;
  width: 90%;
`;

export const IconBox = styled(Flexbox)`
  justify-content: right;
  align-items: center;
  width: 10%;
  padding-top: 2px;
  height: 100%;
`;

export const resolveOrientation = (orientation?: string) =>
  orientation === HORIZONTAL ? HORIZONTAL_STYLE : {};

export const PillSelectContainer: React.FC<{ orientation: string }> = ({
  children,
  orientation,
}) => (
  <Stack {...resolveOrientation(orientation)} space={2}>
    {children}
  </Stack>
);

PillSelectContainer.displayName = 'PillSelectContainer';

export const PILLS_CLUSTER_STYLES = {
  pt: 6,
  mt: 6,
  borderTop: `1px solid ${defaultTheme.colors.border1}`,
};

export const resolvePillsClusterStyles = (index?: number) =>
  index !== 0 ? PILLS_CLUSTER_STYLES : {};

export const PillsCluster: React.FC<{ index?: number }> = ({
  children,
  index,
}) => <Box {...resolvePillsClusterStyles(index)}>{children}</Box>;

PillsCluster.displayName = 'PillsCluster';

export const pillButtonsMapper =
  ({
    selected,
    onSelect,
    size,
  }: Pick<PillSelectProps, 'selected' | 'size' | 'onSelect'>) =>
  ({
    id,
    label,
    disabled,
    onSelectCustom,
    hideDots,
    icon: Icon,
  }: PillSelectOption) => {
    const isSelected =
      (Array.isArray(selected) && selected.includes(id)) || selected === id;

    const labelColor = isSelected
      ? defaultTheme.colors.emerald50
      : defaultTheme.colors.defaultText;

    return (
      <PillButton
        data-test={id}
        disabled={disabled}
        disableAnimations
        fluid
        selected={isSelected}
        key={id}
        onClick={() => {
          onSelectCustom ? onSelectCustom(id) : onSelect(id);
        }}
        ojoType={'secondary'}
        size={size}
      >
        <Flexbox width="100%">
          {/* @ts-ignore */}
          {!!Icon && <Icon />}
          <LabelBox as="span" ml={Icon ? '2' : undefined} color={labelColor}>
            {label}
          </LabelBox>
          <IconBox>
            {isSelected && (
              <IconCheckCircleFill fill={defaultTheme.colors.background1} />
            )}
          </IconBox>
        </Flexbox>
        {onSelectCustom && !hideDots && (
          <ButtonIconContainer size={size} position="right">
            <IconEllipsisHorizontal />
          </ButtonIconContainer>
        )}
      </PillButton>
    );
  };

export const PillSelect: React.FC<PillSelectProps> = ({
  options,
  selected,
  onSelect,
  orientation,
  size = 'md',
  splitOnIndex: index = 0,
}) => {
  const [first, last] = splitOnIndex(index, options);
  const setOrientation = orientation ?? VERTICAL;
  return (
    <Box>
      <PillSelectContainer orientation={setOrientation}>
        {first.map(pillButtonsMapper({ selected, onSelect, size }))}
      </PillSelectContainer>
      {last.length > 0 && (
        <PillsCluster>
          <PillSelectContainer orientation={setOrientation}>
            {last.map(pillButtonsMapper({ selected, onSelect, size }))}
          </PillSelectContainer>
        </PillsCluster>
      )}
    </Box>
  );
};

PillSelect.displayName = 'PillSelect';

export interface PillOption {
  id: string;
  label: string;
  icon?: React.ReactNode;
}

export const asPillOptions = <T extends string>(
  options: Array<PillOption | T>
): Readonly<Array<PillSelectOption>> =>
  options.map(
    option =>
      ({
        id: typeof option === 'string' ? option : (option as PillOption).id,
        label:
          typeof option === 'string' ? option : (option as PillOption).label,
        icon:
          typeof option === 'string' ? undefined : (option as PillOption)?.icon,
      } as PillSelectOption)
  );

export const asPillOption = (
  id: string,
  label: string,
  icon?: React.ReactNode
): PillOption => ({
  id,
  label,
  icon,
});
