import { Box, Flexbox, HorizontalVerticalCenter, Stack } from '@ojolabs/layout';
import React from 'react';
import { Typography } from '@ojolabs/typography';
import { FontIcon } from './FontIcon';
import styled from 'styled-components';
import { defaultTheme } from '../../theme';

interface NextStepCardProps {
  title: string;
  description: string;
  icon: JSX.Element;
  onClick?: () => void;
}

export const PointerFlexbox = styled(Flexbox)`
  cursor: pointer;
`;

export const NextStepCard: React.FC<NextStepCardProps> = ({
  title,
  description,
  icon,
  onClick = () => null,
}) => {
  return (
    <PointerFlexbox
      justifyContent={'space-evenly'}
      flexWrap={'nowrap'}
      alignItems={'center'}
      borderRadius="lg"
      borderWidth={1}
      borderColor={'border1'}
      paddingY={6}
      onClick={onClick}
    >
      <Box width={'2rem'} height={'2rem'}>
        {icon}
      </Box>
      <Stack width={'60%'}>
        <Typography textStyleVariant={'regularBold'} m={0}>
          {title}
        </Typography>
        <Typography textStyleVariant={'regular'} color={'gray50'} m={0}>
          {description}
        </Typography>
      </Stack>
      <HorizontalVerticalCenter
        border={'1px solid'}
        borderRadius={'circle'}
        borderColor={'border1'}
        width={'2rem'}
        height={'2rem'}
      >
        <Box width={'2rem'} height={'2rem'}>
          <FontIcon
            iconName={'arrow-right'}
            style={{
              color: defaultTheme.colors.gray70,
              fontSize: '1.25rem',
              position: 'relative',
              transform: 'translate(-1px, 1px)',
            }}
          />
        </Box>
      </HorizontalVerticalCenter>
    </PointerFlexbox>
  );
};
