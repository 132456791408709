import React from 'react';
import { PillSelect } from './PillSelect';
import { CountryParam } from './AgentApplication';
import { Stack } from '@ojolabs/layout';
import { FlowContainer, TitleContainer } from './FlowContainer';
import { Typography } from '@ojolabs/typography';
import { redirectToOjoAgentApplicationPage } from '../API/queryParamsToRequest';

export interface CountrySelectProps {
  setCountryParam: (countryParam: CountryParam) => void;
}

export const CountrySelectHeader: React.FC = () => (
  <TitleContainer>
    <Typography fontWeight="bold" as="h1" size="2xl">
      Which country are you licensed to service?
    </Typography>
  </TitleContainer>
);

export const CountrySelect: React.FC<CountrySelectProps> = ({
  setCountryParam,
}) => {
  const [selectedOption, setSelectedOption] = React.useState<string>('');

  const options = [
    { label: 'United States', id: 'us' },
    { label: 'Canada', id: 'ca' },
  ];

  const setValidatedCountryParam = () => {
    if (selectedOption === 'ca') {
      setCountryParam('ca');
    } else {
      redirectToOjoAgentApplicationPage();
    }
  };

  return (
    <FlowContainer
      CustomHeader={CountrySelectHeader}
      title={'choose your country'}
      onClickNext={setValidatedCountryParam}
      isDisabled={selectedOption === ''}
      testId={'country-choice'}
    >
      <Stack space={6}>
        <PillSelect
          options={options}
          selected={[selectedOption]}
          onSelect={setSelectedOption}
        />
      </Stack>
    </FlowContainer>
  );
};

CountrySelect.displayName = 'CountrySelect';
