import { createAsyncThunk } from '@reduxjs/toolkit';
import { InitialStateType } from '../initialState';
import { getApiBaseURL } from '../utils';
import { convertStateToRequestFields } from './stateToRequest';
import {
  ApplicationDataFields,
  Country,
  FullRequest,
  ValidPhoneRequest,
} from './types';
import { omitBy, isNil } from 'lodash';

export const getSaveEndpoint = () => `${getApiBaseURL()}/api/funnel/save`;
export const getApplicationDataEndpoint = (id: string) =>
  `${getApiBaseURL()}/api/funnel/${id}`;
export const getValidatePhoneEndpoint = (phone: string) =>
  `${getApiBaseURL()}/api/funnel/validatePhone/${phone}`;

//these are required but currently static
const staticRequestFields = {
  rbcMember: null,
  rbcNumber: null,
  consentChecked: false,
  website: null,
};

export const coalesceDataFields = (
  state: InitialStateType,
  savedApplicationData: ApplicationDataFields
): ApplicationDataFields => {
  const userInputDataFields = omitBy(convertStateToRequestFields(state), isNil);
  return {
    ...savedApplicationData,
    ...userInputDataFields,
  };
};

export const createPostApplicationDataRequestBody = (
  state: InitialStateType,
  savedApplicationData: ApplicationDataFields,
  pageIndex: number,
  completed: boolean,
  sessionId: string,
  country: Country
): FullRequest => {
  const userInputDataFields = omitBy(convertStateToRequestFields(state), isNil);

  return {
    sessionId,
    stepNo: pageIndex,
    data: {
      ...staticRequestFields,
      ...savedApplicationData,
      ...userInputDataFields,
      ...{
        cities: userInputDataFields.cities ?? savedApplicationData.cities,
        country: userInputDataFields.country ?? savedApplicationData.country,
        email: userInputDataFields.email ?? savedApplicationData.email,
        firstName:
          userInputDataFields.firstName ?? savedApplicationData.firstName,
        highOpportunityMarkets:
          userInputDataFields.highOpportunityMarkets ??
          savedApplicationData.highOpportunityMarkets,
        id: userInputDataFields.id ?? savedApplicationData.id,
        lastName: userInputDataFields.lastName ?? savedApplicationData.lastName,
        phone: userInputDataFields.phone ?? savedApplicationData.phone,
        state: userInputDataFields.state ?? savedApplicationData.state,
        zipCodes: userInputDataFields.zipCodes ?? savedApplicationData.zipCodes,
      },
      country: country,
    },
    completed,
  } as FullRequest;
};

interface PostApplicationDataReturnValue {
  sessionId: string;
  profileId?: string;
}

const doNetworkRequest = async (
  input: RequestInfo,
  init?: RequestInit | undefined
) => {
  try {
    const response = await fetch(input, init);
    if (response.status === 200) {
      try {
        const responseBody = await response.json();
        if (responseBody) {
          return responseBody;
        }
      } catch (e) {
        throw new Error(`response body failed to parse ${e}`);
      }
    } else {
      throw new Error(`${response.status}`);
    }
  } catch (e) {
    throw new Error(`request failed ${e}`);
  }
};

export interface ApplicationDataType {
  state: InitialStateType;
  savedApplicationData: ApplicationDataFields;
  pageIndex: number;
  completed: boolean;
  sessionId: string;
  country: Country;
}

export const postApplicationData = createAsyncThunk<
  PostApplicationDataReturnValue | undefined,
  ApplicationDataType
>('postApplicationData', async applicationData => {
  const {
    state,
    savedApplicationData,
    pageIndex,
    completed,
    sessionId,
    country,
  } = applicationData;

  const requestBody = createPostApplicationDataRequestBody(
    state,
    savedApplicationData,
    pageIndex,
    completed,
    sessionId,
    country
  );

  const requestInit = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(requestBody),
  };

  return await doNetworkRequest(getSaveEndpoint(), requestInit);
});

export const getApplicationData = createAsyncThunk<
  Promise<FullRequest>,
  string
>('getApplicationData', async sessionId => {
  return await doNetworkRequest(getApplicationDataEndpoint(sessionId));
});

export const getValidatePhone = createAsyncThunk<
  Promise<ValidPhoneRequest>,
  string
>('getValidatePhone', async phoneNumber => {
  return await doNetworkRequest(getValidatePhoneEndpoint(phoneNumber));
});
