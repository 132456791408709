import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SUCCESS, LOADING, INITIAL, ERROR, RequestStatus } from '../API/types';
import { postApplicationData, getApplicationData } from '../API/client';
import { InitialStateType, initialState } from '../initialState';
import { ConfigTypes } from '../Config/flows';
import { RootState } from '../reduxStore';

export const selectMatchingStateForConfig =
  (config: ConfigTypes) => (state: RootState) => {
    return 'id' in config
      ? state.agentApplication.applicationFields[
          config.id as keyof InitialStateType
        ]
      : undefined;
  };

export const selectAllApplicationFields = () => (state: RootState) => {
  return state.agentApplication.applicationFields;
};

export const selectPostApplicationFieldsStatus = () => (state: RootState) => {
  return state.agentApplication.requestStatuses.postApplicationFieldsStatus;
};

export const selectGetApplicationFieldsStatus = () => (state: RootState) => {
  return state.agentApplication.requestStatuses.getApplicationFieldsStatus;
};

export const selectIsFlowCompleted = () => (state: RootState) => {
  return state.agentApplication.flowCompleted;
};

const initialReduxState = {
  requestStatuses: {
    postApplicationFieldsStatus: INITIAL as RequestStatus,
    getApplicationFieldsStatus: INITIAL as RequestStatus,
  },
  applicationFields: initialState,
  flowCompleted: false,
};

export interface SetTextInputAnswerPayload {
  configId: keyof InitialStateType;
  fieldId: string;
  newValue: string;
  isInvalidInput?: boolean;
}

export const agentApplicationSlice = createSlice({
  name: 'agentApplicationSlice',
  initialState: initialReduxState,
  reducers: {
    setFlowCompleted: (state, action: PayloadAction<boolean>) => {
      state.flowCompleted = action.payload;
    },
    setTextInputAnswer: (
      state,
      action: PayloadAction<SetTextInputAnswerPayload>
    ) => {
      const { configId, fieldId, newValue, isInvalidInput } = action.payload;

      const targetState =
        state.applicationFields[configId as keyof InitialStateType];

      if ('fields' in targetState) {
        targetState.fields[fieldId].value = newValue;
        if (isInvalidInput !== undefined) {
          targetState.fields[fieldId].isInvalidInput = isInvalidInput;
        }
      }
    },
    setMultipleChoiceAnswer: (
      state,
      action: {
        payload: {
          configId: string;
          newSelected?: string[];
          newFreeformText?: string;
        };
      }
    ) => {
      const { configId } = action.payload;
      const targetState =
        state.applicationFields[configId as keyof InitialStateType];

      if ('selected' in targetState) {
        const {
          newSelected = targetState.selected,
          newFreeformText = targetState.otherFreeformText,
        } = action.payload;
        targetState.selected = newSelected;
        targetState.otherFreeformText = newFreeformText;
      }
    },
    updateEntireApplicationState: (
      state,
      action: PayloadAction<InitialStateType>
    ) => {
      state.applicationFields = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(postApplicationData.pending, state => {
      state.requestStatuses.postApplicationFieldsStatus = LOADING;
    });
    builder.addCase(postApplicationData.fulfilled, state => {
      state.requestStatuses.postApplicationFieldsStatus = SUCCESS;
    });
    builder.addCase(postApplicationData.rejected, state => {
      state.requestStatuses.postApplicationFieldsStatus = ERROR;
    });
    builder.addCase(getApplicationData.pending, state => {
      state.requestStatuses.getApplicationFieldsStatus = LOADING;
    });
    builder.addCase(getApplicationData.fulfilled, state => {
      state.requestStatuses.getApplicationFieldsStatus = SUCCESS;
    });
    builder.addCase(getApplicationData.rejected, state => {
      state.requestStatuses.getApplicationFieldsStatus = ERROR;
    });
  },
});

export const {
  setTextInputAnswer,
  setMultipleChoiceAnswer,
  setFlowCompleted,
  updateEntireApplicationState,
} = agentApplicationSlice.actions;

export default agentApplicationSlice.reducer;
