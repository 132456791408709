// COPIED FROM CONSUMER APP
// IMPORT FROM FOUNDATIONS ONCE ADDED http://foundation.ojointernal.com/
//ojolabs.atlassian.net/browse/CN-4045

import { Box, Stack } from '@ojolabs/layout';
import { Typography } from '@ojolabs/typography';
import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

export type AlertType = 'success' | 'warning' | 'error' | 'info';

interface ILineBox {
  type: AlertType;
}
export const LineBox = styled(Box)<ILineBox>`
  ${variant({
    prop: 'type',
    variants: {
      success: {
        borderLeftColor: 'green50',
      },
      warning: {
        borderLeftColor: 'yellow40',
      },
      error: {
        borderLeftColor: 'red50',
      },
      info: {
        borderLeftColor: 'emerald50',
      },
    },
  })}
`;

interface IContainerBox {
  type: AlertType;
}

const ContainerBox = styled(Box)<IContainerBox>`
  ${variant({
    prop: 'type',
    variants: {
      info: {
        backgroundColor: 'blue50',
      },
      error: {
        backgroundColor: 'orange100',
      },
    },
  })}
`;

export interface IAlertBoxBody {
  title: string;
}
export const AlertBoxBody: React.FC<IAlertBoxBody> = ({
  title = '',
  children: body,
}) => {
  return (
    <Stack space={2}>
      {!!title && (
        <Typography as="span" weight="bold" fontWeight="700">
          {title}
        </Typography>
      )}
      {!!body && (
        <Typography as="span" fontFamily="DM Sans">
          {body}
        </Typography>
      )}
    </Stack>
  );
};

const ALERT_BORDER_RADIUS = '8px';

export interface IAlertBox {
  type?: AlertType;
  title?: string;
}
export const AlertBox: React.FC<IAlertBox> = ({ type = 'info', children }) => {
  return (
    <ContainerBox
      type={type}
      bg="white"
      borderWidth="1px"
      borderLeftWidth={0}
      borderStyle="solid"
      borderColor="gray300"
      borderRadius={ALERT_BORDER_RADIUS}
      position="relative"
    >
      <LineBox
        type={type}
        borderLeftWidth="0.5rem"
        borderLeftStyle="solid"
        borderTopLeftRadius={ALERT_BORDER_RADIUS}
        borderBottomLeftRadius={ALERT_BORDER_RADIUS}
        height="calc(100% + 2px)"
        marginTop="-1px"
        position="absolute"
      />
      <Box py={4} px={6}>
        {children}
      </Box>
    </ContainerBox>
  );
};

AlertBox.displayName = 'AlertBox';
