import * as React from 'react';
import styled from 'styled-components';
import { Box, Center } from '@ojolabs/layout';

const PageContainerStyled = styled(Box)`
  position: fixed;
  padding: ${props => props.theme.space[5]};
  max-width: ${props => props.theme.breakpoints[0]}px;
  width: 100%;
  height: 100%;
  overflow: scroll;

  /* hide the scrollbar but still have it usable */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PageContainer: React.FC = ({ children }) => {
  return (
    <Center centerChildren={true}>
      <PageContainerStyled>{children}</PageContainerStyled>
    </Center>
  );
};
