import * as React from 'react';
import {
  MultipleChoiceScreenConfig,
  MULTIPLE_CHOICE_OTHER_ID,
  MULTIPLE_CHOICE,
  MULTIPLE_CHOICE_CHECK,
  VERTICAL,
} from '../Config/flowTypes';
import { PillSelect } from './PillSelect';
import { MultipleChoiceState } from '../initialState';
import { last } from 'lodash';
import { Stack } from '@ojolabs/layout';
import { MultiLineTextField } from '@ojolabs/text-field';
import { Typography } from '@ojolabs/typography';
import { CheckboxSelect } from './CheckboxSelect';

export interface MultipleChoiceScreenProps {
  config: MultipleChoiceScreenConfig;
  matchingState: MultipleChoiceState;
  setSelected: (newSelected: string[]) => void;
  // Only required if 'Other' is an option & to be expanded to freeform
  onOtherFreeformTextChange?: (text: string) => void;
  inputType?: typeof MULTIPLE_CHOICE | typeof MULTIPLE_CHOICE_CHECK;
}

export const MultipleChoiceScreen: React.FC<MultipleChoiceScreenProps> = ({
  config,
  matchingState,
  setSelected,
  onOtherFreeformTextChange,
}) => {
  const {
    options,
    otherFreeformCustomId = MULTIPLE_CHOICE_OTHER_ID,
    expandOtherFreeform = true,
  } = config;
  const { selected } = matchingState;
  const orientation = config.orientation ?? VERTICAL;
  const onSelect = (id: string) => {
    const currentlySelected = selected;
    const alreadySelected = currentlySelected.includes(id);

    //if already selected - unselect
    if (alreadySelected) {
      setSelected(currentlySelected.filter(existingId => existingId !== id));
    } else {
      //if multiSelect enabled, add new id to selected
      if (config.multiSelect) {
        setSelected([...currentlySelected, id]);
      } else {
        //else set selected to just new id
        setSelected([id]);
      }
    }
  };

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const hasOtherOption = options.find(
        o => o.id === otherFreeformCustomId || o.label.toLowerCase() === 'other'
      );

      if (
        expandOtherFreeform &&
        hasOtherOption &&
        last(options)?.id !== otherFreeformCustomId
      ) {
        /* tslint:disable no-console */
        console.warn(
          "WARNING: Expanding 'Other' to be freeform text field when 'Other' isn't the last option."
        );
      } else if (expandOtherFreeform && !onOtherFreeformTextChange) {
        /* tslint:disable no-console */
        console.warn(
          'WARNING: Attempting to expand "Other" but did not provide a callback. Skip rendering'
        );
      }
    }
  }, [expandOtherFreeform, otherFreeformCustomId, !!onOtherFreeformTextChange]);

  const OptionsSelect =
    config.type === MULTIPLE_CHOICE ? (
      <PillSelect
        options={options}
        selected={selected}
        onSelect={onSelect}
        orientation={orientation}
      />
    ) : (
      <CheckboxSelect
        options={options}
        selected={selected}
        onSelect={onSelect}
      />
    );

  if (!expandOtherFreeform) {
    return OptionsSelect;
  }

  const showOtherFreeform =
    expandOtherFreeform &&
    selected.includes(otherFreeformCustomId) &&
    !!onOtherFreeformTextChange;

  return (
    <Stack space={6}>
      {OptionsSelect}
      {showOtherFreeform && (
        <Stack space={2}>
          <Typography textStyleVariant={'smallBold'}>
            {'If other, please specify'}
          </Typography>
          <MultiLineTextField
            className={'textarea-foundation'}
            id={otherFreeformCustomId + '_text'}
            placeholder={config.otherPlaceholder ?? 'Write a comment...'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onOtherFreeformTextChange(e.target.value)
            }
            resize={'none'}
            state={'valid'}
            maxLength={255}
            value={matchingState.otherFreeformText}
            fluid
            rows={3}
          />
        </Stack>
      )}
    </Stack>
  );
};

MultipleChoiceScreen.displayName = 'MultipleChoiceScreen';
