// Base theme object for styled-component (and styled-system).
// This object can be extended based on
// styled-system specs: https://styled-system.com/theme-specification/
// Worth to mention that this object is also used by
// some OJO frontend libraries like @ojolabs/layout.
import {
  colors,
  radii,
  shadows,
  fontSizes,
  letterSpacings,
  lineHeights,
  fontWeights,
  fonts,
  space,
  ojoTheme2024,
} from '@ojolabs/theme2024';

import { colors as prevColors } from '@ojolabs/theme';

const prevSpaces = {
  0: '0', // 0
  1: '0.25rem', // 1
  2: '0.5rem', // 2
  3: '0.75rem', // 3
  4: '1rem', // 4
  5: '1.25rem', // 5
  6: '1.5rem', // 6
  7: '2rem', // 7
  8: '2.5rem', // 8
  9: '3rem', // 9
  10: '4rem', // 10
  11: '5rem', // 11
  12: '7.5rem', // 12
};

export const defaultTheme = {
  colors: {
    ...prevColors,
    ...colors,
    red600: '#bf2600',
    ...ojoTheme2024.colors,
  },
  fonts,
  fontSizes: { ...fontSizes },
  fontWeights: {
    ...fontWeights,
  },
  letterSpacings: {
    ...letterSpacings,
  },
  lineHeights: {
    ...lineHeights,
  },
  radii: {
    ...radii,
  },
  shadows: {
    ...shadows,
  },
  space: {
    ...prevSpaces,
    ...space,
  },
  breakpoints: [480],
  zIndex: {
    overMapIndex: 1000,
    modalIndex: 2000,
  },
};

export default defaultTheme as typeof defaultTheme;
