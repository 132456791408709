import React from 'react';
import { HorizontalVerticalCenter } from '@ojolabs/layout';

interface FontIconProps extends React.HTMLAttributes<HTMLElement> {
  iconName: string;
}

export const FontIcon: React.FunctionComponent<FontIconProps> = ({
  iconName,
  className,
  ...otherProps
}) => {
  return (
    <HorizontalVerticalCenter
      as={'i'}
      className={[`icon-${iconName}`, className].filter(Boolean).join(' ')}
      {...otherProps}
    />
  );
};
