import { getApplicationData } from './API/client';
import { getSessionId } from './API/queryParamsToRequest';
import { FullRequest } from './API/types';
import { AppDispatch } from './reduxStore';

//attempts to get saved application data by looking for a sessionId and sending it to API
//returns null if any step fails along the way
export const getExistingApplicationData = async (
  dispatch: AppDispatch
): Promise<FullRequest | null> => {
  const existingSessionId = getSessionId();

  if (existingSessionId) {
    const previouslySavedApplication = await dispatch(
      getApplicationData(existingSessionId)
    )
      .unwrap()
      .catch();

    if (!previouslySavedApplication) {
      return null;
    }
    return previouslySavedApplication;
  }

  return null;
};
